import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from "reactstrap";

import Link from "components/ExtendLink";
import { NavLink } from "reactstrap";
import axios from "axios";
import { USERS_ROLE } from "../../../constants/role.constant";
const CreateUserModal = (props) => {
  const [emailAvailable, setEmailAvailable] = useState({
    validate: true,
    message: ""
  });
  const [usernameAvailable, setUsernameAvailable] = useState({
    validate: true,
    message: ""
  });
  const roleOptions = USERS_ROLE.filter((item) => item.type == "Careprovider");
  return (
    <Modal
      isOpen={props.modalUser}
      toggle={props.toggleUser}
      backdrop="static"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={props.toggleUser}>
        Create a CareProvider Account
      </ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={props.submitUser}>
          <FormGroup>
            <Label for="exampleText">
              MemberID (Reference ID OF CLIENT'S DB)
            </Label>
            <Input
              type="text"
              name="memberId"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Email</Label>
            <Input
              type="email"
              name="email"
              onChange={props.handleInputChange}
              onBlur={(e) => {
                axios
                  .get("/check-availability?email=" + e.target.value)
                  .then(function (response) {
                    
                    setEmailAvailable({ validate: true, message: "" });
                  })
                  .catch(function (error) {
                    const { response = {} } = error;
                    const { data = {} } = response;
                    const { message = "" } = data;

                    setEmailAvailable({
                      validate: false,
                      message: message
                    });
                  });
              }}
              invalid={!emailAvailable.validate}
              rows={10}
            />
            <FormFeedback>{emailAvailable.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Username</Label>
            <Input
              type="text"
              name="username"
              required={true}
              onBlur={(e) => {
                axios
                  .get("/check-availability?username=" + e.target.value)
                  .then(function (response) {
                    // handle success
                    setUsernameAvailable({ validate: true, message: "" });
                  })
                  .catch(function (error) {
                    const { response = {} } = error;
                    const { data = {} } = response;
                    const { message = "" } = data;

                    setUsernameAvailable({
                      validate: false,
                      message: message
                    });
                  });
              }}
              invalid={!usernameAvailable.validate}
              onChange={props.handleInputChange}
              rows={10}
            />
            <FormFeedback>{usernameAvailable.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
          <Label for="exampleText">Role *</Label>
          <select
            name="role"
            className="form-control"
            onChange={props.handleRole}
            required
          >
          <option value="">
            Select Role
          </option>
          {roleOptions.map((option) => (
            <option value={option.value}>{option.role}</option>
          ))}
          </select>
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Password</Label>
            <Input
              type="password"
              name="password"
              required={true}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Confirm Password</Label>
            <Input
              invalid={props.errorPassword}
              type="password"
              className="form-control form-control-lg"
              placeholder="Confirm Password"
              name="confirmpassword"
              onChange={props.handleInputChange}
              rows={10}
            />

            {props.errorPassword && (
              <FormFeedback>Password does not match</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">First Name</Label>
            <Input
              type="text"
              name="fname"
              required={true}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Last Name</Label>
            <Input
              type="text"
              name="lname"
              required={true}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Nickname</Label>
            <Input
              type="text"
              name="nickname"
              required={false}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">SubOrganization</Label>
            <Input
              type="text"
              name="subOrganization"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">DOB</Label>
            <Input
              type="date"
              name="bday"
              required={true}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Phone</Label>
            <Input
              type="text"
              name="phone"
              required={true}
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Home Number</Label>
            <Input
              type="text"
              name="homeNumber"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Work Number</Label>
            <Input
              type="text"
              name="workNumber"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Fax Number</Label>
            <Input
              type="text"
              name="fax"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Address</Label>
            <Input
              type="text"
              name="address"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>

          <FormGroup>
            <Label for="exampleText">City</Label>
            <Input
              type="text"
              name="city"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">State</Label>
            <Input
              type="text"
              name="state"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Zip Code</Label>
            <Input
              type="text"
              name="zip"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Height (ft)</Label>
            <Input
              type="text"
              name="height"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Weight (lb)</Label>
            <Input
              type="text"
              name="weight"
              onChange={props.handleInputChange}
              rows={10}
            />
          </FormGroup>
          <div className="form-group">
            <label>Gender</label>
            <select
              name="gender"
              className="form-control"
              defaultValue="Male"
              onChange={props.handleInputChange}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          <button type="submit" className="btn btn-block btn-info">
            SAVE
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateUserModal;
