import classnames from "classnames";
import { filter, omit, size } from "lodash";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import addUsersImage from "../../../files/add-users.png";
import ActivePatientList from "./ActivePatientList";
import CreateUserModal from "./CreateUserModal";
import Heading from "./Heading";
import CreateSharedAccountModal from "./CreateSharedAccountModal";
import familyAccount from "../../../files/ic_family_account.svg";
import ImprovedPatientList from "./ImprovedPatientList";
import PatientList from "./PatientList";
import ResetPassword from "./ResetPassword";
import { patientsTab } from "../../../constants/general.constant";
import queryString from 'query-string';
import usersImage from "../../../files/users.png";
const MENU_API = {
  ALL_PATIENT: "AllPatient",
  ACTIVE_PATIENT: "Active",
  IN_ACTIVE_PATIENT: "InActive",
  DEMO_PATIENT: "Demo",
  CALLER_PATIENT: "Caller",
  NEED_TO_FOLLOW_UP:"NeedToFollowUp",
  HOSPITAL_ADMINSSON:"HospitalAdmission",
  IT_ISSUES : "ItIssues",
  FOLLOW_UP_REMINDERS : 'FollowUpReminder',
  REMINDER_SENT : 'ReminderSent',
  WANRNING_ADHERENCE : 'WarningAdherence'
}
class AdminAnalytics extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      sharedmodal: false,
      activeTab: "1",
      modal: false,
      modal2: false,
      modalUser: false,
      modalReset: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      subOrganization: "",
      gender: "Male",
      showAlertSuccess: false,
      errorPassword: false,
      isClient: true,
      resetFullname: "",
      resetPatientId: "",
      showWarning: false,
      careprovider: false,
      displayActiveList: true,
      displayDemoList: null,
      insuranceProvider: "",
      enrolledPrograms: "",
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayItIssuesList : false,
      loading: true,
      selectedApi: "Active",
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false,
      modalShared: false,
      role: ""
    };
    this.toggleUser = this.toggleUser.bind(this);
    this.toggleReset = this.toggleReset.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.submitResetPassword = this.submitResetPassword.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.resetStateBack = this.resetStateBack.bind(this);
    this.child = React.createRef();
    this.toggleShared = this.toggleShared.bind(this);
    this.submitShared = this.submitShared.bind(this);
    this.toggleSharedModal = this.toggleSharedModal.bind(this);
    this.handleRole = this.handleRole.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  handleRole(e){
    this.setState({ role: e == null ? "" : e.target.value });
  }
  resetStateBack() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      modalReset: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      subOrganization: "",
      gender: "Male",
      role: "",
      isClient: true,
      resetFullname: "",
      resetPatientId: "",
      sharedmodal: false,
      modalShared: false,
    });
  }
  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      modalReset: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      subOrganization: "",
      gender: "Male",
      role: "",
      isClient: true
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_YOU_HAVE_ACCESS",
    //   payload: { client: true }
    // });
    this.setState({
      selectedApi: MENU_API.ACTIVE_PATIENT,
      displayActiveList: true,
      displayDemoList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
  }

  submitUser(e) {
    e.preventDefault();
    if (this.state.errorPassword) {
      return;
    }
    const cleanUser = omit(this.state, [
      "activeTab",
      "modal",
      "modal2",
      "modalUser",
      "modalReset",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
      "resetFullname",
      "resetPatientId",
      "showWarning",
      "displayActiveList",
      "displayDemoList",
      "displayCellularConnectivityList",
      "displayNeedToFollowUpList",
      "displayHospitalAddmissionList",
      "displayItIssuesList",
      "loading",
      "selectedApi",
      "displayFollowUpReminderList",
      "displayReminderSentList",
      "displayWarningAdherence",
      "sharedmodal",
      "modalShared",
    ]);
    this.props.dispatch({
      type: "DO_SAVE_USER",
      payload: cleanUser
    });
  }
  submitReset(e) {
    e.preventDefault();
    if (this.state.errorPassword) {
      return;
    }
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  submitShared(e) {
    e.preventDefault();
    if (this.state.errorPassword) {
      return;
    }
    const cleanUser = omit(this.state, [
      "activeTab",
      "modal",
      "modal2",
      "sharedmodal",
      "modalShared",
      "modalUser",
      "modalReset",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
      "resetFullname",
      "resetPatientId",
      "showWarning",
      "displayActiveList",
      "displayDemoList",
      "displayCellularConnectivityList",
      "displayNeedToFollowUpList",
      "loading",
      "selectedApi",
      "isClient",
      "careprovider",
    ]);
    this.props.dispatch({
      type: "DO_SAVE_SHARED",
      payload: cleanUser
    });
  }
  toggleShared() {
    const previousState = this.state.modalShared;

    this.setState({
      modalShared: !this.state.modalShared
    });
    if (previousState) {
      this.resetForm();
    }
  }
  submitResetPassword() {
    this.props.dispatch({
      type: "DO_RESET_PATIENT_PASSWORD",
      payload: {
        user: this.state.resetPatientId,
        password: this.state.password
      }
    });
    this.setState({
      showWarning: !this.state.showWarning
    });
  }
  toggleUser() {
    const previousState = this.state.modalUser;

    this.setState({
      modalUser: !this.state.modalUser
    });
    if (previousState) {
      this.resetForm();
    }
  }
  toggleReset(fullname, patientId) {
    const previousState = this.state.modalReset;

    this.setState({
      modalReset: !this.state.modalReset,
      resetFullname: fullname,
      resetPatientId: patientId
    });
    if (previousState) {
      this.resetStateBack();
    }
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggleSharedModal() {
    this.setState({
      sharedmodal: !this.state.sharedmodal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleDisplayActiveList(display) {
    if (display) {
      //Display active list
      this.setState({
        selectedApi: MENU_API.ACTIVE_PATIENT,
        displayActiveList: true,
        displayDemoList: null,
        displayCellularConnectivityList: false,
        displayNeedToFollowUpList: false,
        displayHospitalAddmissionList: false,
        displayItIssuesList : false,
        displayFollowUpReminderList : false,
        displayReminderSentList : false,
        displayWarningAdherence: false
      }, () => {
        this.fetchData();
      });
      this.setQueryParams({tab : patientsTab.active})
    } else {
      // inactive list clicked
      this.setState({
        selectedApi: MENU_API.IN_ACTIVE_PATIENT,
        displayActiveList: false,
        displayDemoList: null,
        displayCellularConnectivityList: false,
        displayNeedToFollowUpList: false,
        displayHospitalAddmissionList: false,
        displayItIssuesList : false,
        displayFollowUpReminderList : false,
        displayReminderSentList : false,
        displayWarningAdherence: false
      }, () => {
        this.fetchData();
      });
      this.setQueryParams({tab : patientsTab.inActive})
    }

  }

  toggleCellularConnectivityList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.CALLER_PATIENT,
      displayCellularConnectivityList: true,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.cellurConnectivity})
  }

  toggleNeedToFollowUpList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.NEED_TO_FOLLOW_UP,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: true,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.needToFollowUp})
  }

  toggleHospitalAdmissionList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.HOSPITAL_ADMINSSON,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: true,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.hospitalAdmission})
  }
  toggleItIssueslAdmissionList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.IT_ISSUES,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : true,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.itIssue})
  }
  toggleFollowUpRemindersList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.FOLLOW_UP_REMINDERS,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : true,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.followUpReminders})
  }
  toggleRemindeSentList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.REMINDER_SENT,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : true,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.reminderSent})
  }
  toggleDemoList(){
    this.setState({
      selectedApi: MENU_API.DEMO_PATIENT,
      displayDemoList: true,
      displayActiveList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.demo})

  }
  toggleAllPatientsList(){
    this.setState({
      displayDemoList: false,
      displayActiveList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayItIssuesList : false,
      selectedApi: MENU_API.ALL_PATIENT,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.all})
  }
  setQueryParams(newParams){
    const currentParams = queryString.parse(window.location.search);
    // Merge current and new query parameters
    const updatedParams = { ...currentParams, ...newParams };

    // Create the new query string
    const newQueryString = queryString.stringify(updatedParams);

    // Update the URL with new query params
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${newQueryString}`,
    });
  }
  toggleWarningAdherenceList() {
    //Display cellular connectivity list        
    this.setState({
      selectedApi: MENU_API.WANRNING_ADHERENCE,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayActiveList: null,
      displayDemoList: null,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: true
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.didNotQualifyLastMonth})
  }
  toggleDemoList(){
    this.setState({
      selectedApi: MENU_API.DEMO_PATIENT,
      displayDemoList: true,
      displayActiveList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayItIssuesList : false,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.demo})

  }
  toggleAllPatientsList(){
    this.setState({
      displayDemoList: false,
      displayActiveList: null,
      displayCellularConnectivityList: false,
      displayNeedToFollowUpList: false,
      displayHospitalAddmissionList: false,
      displayItIssuesList : false,
      selectedApi: MENU_API.ALL_PATIENT,
      displayFollowUpReminderList : false,
      displayReminderSentList : false,
      displayWarningAdherence: false
    }, () => {
      this.fetchData();
    });
    this.setQueryParams({tab : patientsTab.all})
  }
  setQueryParams(newParams){
    const currentParams = queryString.parse(window.location.search);
    // Merge current and new query parameters
    const updatedParams = { ...currentParams, ...newParams };

    // Create the new query string
    const newQueryString = queryString.stringify(updatedParams);

    // Update the URL with new query params
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${newQueryString}`,
    });
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_ALL_PATIENT_COUNT"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
    this.props.dispatch({
      type: "DO_GET_CREATE_PATIENT_PERMISSION"
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_YOU_HAVE_ACCESS",
    //   payload: { client: true }
    // });
    this.props.dispatch({
      type: "DO_GET_PROFILE",
      payload: { authProfile: true }
    });
    // this.props.dispatch({
    //   type: "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION"
    // });
    const { tab } = queryString.parse(window.location.search);
    if(tab == patientsTab.all){
      this.toggleAllPatientsList();
    }else if(tab == patientsTab.active){
      this.toggleDisplayActiveList(true)
    }else if(tab == patientsTab.inActive){
      this.toggleDisplayActiveList(false)
    }else if(tab == patientsTab.demo){
      this.toggleDemoList();
    }else if(tab == patientsTab.cellurConnectivity){
      this.toggleCellularConnectivityList();
    }else if(tab == patientsTab.needToFollowUp){
      this.toggleNeedToFollowUpList()
    }else if(tab == patientsTab.hospitalAdmission){
      this.toggleHospitalAdmissionList()
    }else if(tab == patientsTab.reminderSent){
      this.toggleRemindeSentList()
    }else if(tab == patientsTab.followUpReminders){
      this.toggleFollowUpRemindersList()
    }else if(tab == patientsTab.itIssue){
      this.toggleItIssueslAdmissionList()
    }else if(tab == patientsTab.didNotQualifyLastMonth){
      this.toggleWarningAdherenceList()
    }
    // else{
    //   this.toggleDisplayActiveList(true)
    // }
  }
  render() {
    const numberActive = this.props.allPatientCount ? this.props.allPatientCount.activePatients : 0;
    const numberInactive = this.props.allPatientCount ? this.props.allPatientCount.inActivePatients : 0;
    const numberDemo = this.props.allPatientCount ? this.props.allPatientCount.demoPatients : 0;
    const allPatients = this.props.allPatientCount ? this.props.allPatientCount.allPatients : 0;
    const numberCellularConnectivity = this.props.allPatientCount ? this.props.allPatientCount.cellularConnectivityPatients : 0;
    const needToFollowUp = this.props.allPatientCount ? this.props.allPatientCount.needToFollowUp : 0;
    const hospitalAddmission = this.props.allPatientCount ? this.props.allPatientCount.hospitalAddmission : 0;
    const itIssues = this.props.allPatientCount ? this.props.allPatientCount.itIssues : 0;
    const followUpReminders = this.props.allPatientCount && this.props.allPatientCount.followUpReminders ? this.props.allPatientCount.followUpReminders : 0;
    const reminderSent = this.props.allPatientCount && this.props.allPatientCount.reminderSent ? this.props.allPatientCount.reminderSent : 0;
    const wanringAdherences = this.props.allPatientCount && this.props.allPatientCount.wanringAdherences ? this.props.allPatientCount.wanringAdherences : 0;
    var allPatientsCard = { backgroundColor: "#198ae3", color: "white" };
    var activePatientsCard = { backgroundColor: "white", color: "black" };
    var inActivePatientsCard = { backgroundColor: "white", color: "black" };
    var demoPatientsCard = { backgroundColor: "white", color: "black" };
    var cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
    var needToFollowUpPatientsCard = { backgroundColor: "white", color: "black"};
    var hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
    var itIssuesPatientCard = { backgroundColor: "white", color: "black"};
    var followUpRemindeCard = { backgroundColor: "white", color: "black"};
    var remindeSentCard = { backgroundColor: "white", color: "black"};
    var warningAdherenceCard = { backgroundColor: "white", color: "black"};
    if (this.state.displayActiveList == true) {
      //Active List
      //this.setState({selectedApi:MENU_API.ALL_PATIENT});
      activePatientsCard = { backgroundColor: "#198ae3", color: "white" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayActiveList == false) {
      //Inactive list      
      //this.setState({selectedApi:MENU_API.ACTIVE_PATIENT});
      inActivePatientsCard = { backgroundColor: "#198ae3", color: "white" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = {
        backgroundColor: "white",
        color: "black"
      };
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayDemoList) {
      // Demo List
      //this.setState({selectedApi:MENU_API.DEMO_PATIENT});
      demoPatientsCard = { backgroundColor: "#198ae3", color: "white" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = {
        backgroundColor: "white",
        color: "black"
      };
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayCellularConnectivityList) {
      // Cellular Connectivity List
      cellularConnectivityPatientsCard = {
        backgroundColor: "#198ae3",
        color: "white"
      };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayNeedToFollowUpList) {
      // Cellular Connectivity List
      needToFollowUpPatientsCard = { backgroundColor: "#198ae3", color: "white" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if (this.state.displayHospitalAddmissionList) {
      // Cellular Connectivity List
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "#198ae3", color: "white" };
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    }else if(this.state.displayItIssuesList){
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "#198ae3", color: "white" };
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    }else if(this.state.displayFollowUpReminderList){
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "#198ae3", color: "white" };
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if(this.state.displayReminderSentList){
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "#198ae3", color: "white" };
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "white", color: "black"};
    } else if(this.state.displayWarningAdherence){
      needToFollowUpPatientsCard = { backgroundColor: "white", color: "black" };
      inActivePatientsCard = { backgroundColor: "white", color: "black" };
      activePatientsCard = { backgroundColor: "white", color: "black" };
      allPatientsCard = { backgroundColor: "white", color: "black" };
      demoPatientsCard = { backgroundColor: "white", color: "black" };
      cellularConnectivityPatientsCard = { backgroundColor: "white", color: "black"};
      hospitalAddmissionPatientsCard = { backgroundColor: "white", color: "black"};
      remindeSentCard = { backgroundColor: "white", color: "black"};
      itIssuesPatientCard = { backgroundColor: "white", color: "black"};
      followUpRemindeCard = { backgroundColor: "white", color: "black"};
      warningAdherenceCard = { backgroundColor: "#198ae3", color: "white" };
    }
    return (
      <div>
        <div className="row">
          <Heading
            title="PATIENTS"
            description=""
            toggleModal={this.toggleModal}
            toggleSharedModal={this.toggleSharedModal}
            backbutton="d-none"
            mainbutton="d-block"
            checkPermission = {this.props.createPatientPermission}
          >
            <span className="page-title-icon bg-gradient-danger   text-white mr-2">
              <i className="mdi mdi-account-convert" />
            </span>
          </Heading>
          <div className="col-12 grid-margin remove-pading-table">
            <div className="card">
              <div className="card-body project-tab pt-2">
                <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                    <img className="img-tabsfluid mr-2" src={usersImage} />
                      PATIENTS
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  <Col sm="12" className="grid-margin mt-4 remove-pading-table">
                    {!this.props.allPatientCount && (
                      <ReactLoading className="mx-auto" color={"#007bff"} />
                    )}
                    {this.props.allPatientCount && (
                      <div>
                      <div className="d-flex flex-wrap flex-fill bd-highlight">
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {
                          this.toggleAllPatientsList();
                        }}
                      >
                        <div
                          className="card-content"
                          style={allPatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>All</h4>
                                <h1>{allPatients}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {this.toggleDisplayActiveList(true)}}
                      >
                        <div
                          className="card-content"
                          style={activePatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Active</h4>
                                <h1>{numberActive}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight" 
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {
                          this.toggleDemoList()
                        }}
                      >
                        <div
                          className="card-content"
                          style={demoPatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Demo</h4>
                                <h1>{numberDemo}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {this.toggleDisplayActiveList(false)}}
                      >
                        <div
                          className="card-content"
                          style={inActivePatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Inactive</h4>
                                <h1>{numberInactive}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() =>{
                          this.toggleCellularConnectivityList();
                        }
                        }
                      >
                        <div
                          className="card-content"
                          style={cellularConnectivityPatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Cellular Connectivity</h4>
                                <h1>{numberCellularConnectivity}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {
                          this.toggleNeedToFollowUpList()
                        }
                        }
                      >
                        <div
                          className="card-content"
                          style={needToFollowUpPatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Need To Follow UP</h4>
                                <h1>{needToFollowUp}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card overflow-hidden m-2 flex-fill bd-highlight"
                        style={{ pointerEvents: "yes" }}
                        onClick={() => {
                          this.toggleHospitalAdmissionList()
                        }
                        }
                      >
                        <div
                          className="card-content"
                          style={hospitalAddmissionPatientsCard}
                        >
                          <div className="card-body cleartfix">
                            <div className="media align-items-stretch">
                              <div className="align-self-center">
                                <i className="icon-pencil primary font-large-2 mr-2" />
                              </div>
                              <div className="media-body text-center">
                                <h4>Hospital Admission</h4>
                                <h1>{hospitalAddmission}</h1>
                              </div>
                              <div className="align-self-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div
                      className="card overflow-hidden m-2 flex-fill bd-highlight"
                      style={{ pointerEvents: "yes" }}
                      onClick={() => {
                        this.toggleItIssueslAdmissionList()
                      }
                      }
                    >
                      <div
                        className="card-content"
                        style={itIssuesPatientCard}
                      >
                        <div className="card-body cleartfix">
                          <div className="media align-items-stretch">
                            <div className="align-self-center">
                              <i className="icon-pencil primary font-large-2 mr-2" />
                            </div>
                            <div className="media-body text-center">
                              <h4>IT Issues</h4>
                              <h1>{itIssues}</h1>
                            </div>
                            <div className="align-self-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                    className="card overflow-hidden m-2 flex-fill bd-highlight"
                    style={{ pointerEvents: "yes" }}
                    onClick={() => {
                      this.toggleFollowUpRemindersList()
                    }
                    }
                  >
                    <div
                      className="card-content"
                      style={followUpRemindeCard}
                    >
                      <div className="card-body cleartfix">
                        <div className="media align-items-stretch">
                          <div className="align-self-center">
                            <i className="icon-pencil primary font-large-2 mr-2" />
                          </div>
                          <div className="media-body text-center">
                            <h4>Follow Up Reminders</h4>
                            <h1>{followUpReminders}</h1>
                          </div>
                          <div className="align-self-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                  className="card overflow-hidden m-2 flex-fill bd-highlight"
                  style={{ pointerEvents: "yes" }}
                  onClick={() => {
                    this.toggleRemindeSentList()
                  }
                  }
                >
                  <div
                    className="card-content"
                    style={remindeSentCard}
                  >
                    <div className="card-body cleartfix">
                      <div className="media align-items-stretch">
                        <div className="align-self-center">
                          <i className="icon-pencil primary font-large-2 mr-2" />
                        </div>
                        <div className="media-body text-center">
                          <h4>Reminder Sent</h4>
                          <h1>{reminderSent}</h1>
                        </div>
                        <div className="align-self-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                className="card overflow-hidden m-2 flex-fill bd-highlight"
                style={{ pointerEvents: "yes" }}
                onClick={() =>
                  this.toggleWarningAdherenceList()
                }
              >
                <div
                  className="card-content"
                  style={warningAdherenceCard}
                >
                  <div className="card-body cleartfix">
                    <div className="media align-items-stretch">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 mr-2" />
                      </div>
                      <div className="media-body text-center">
                        <h4>Did Not Qualify Last Month</h4>
                        <h1>{wanringAdherences}</h1>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
                </div>
                        <ActivePatientList
                          fetchDataFromServer={(click) =>
                            (this.fetchData = click)
                          }
                          selectedApi={this.state.selectedApi}
                          profileOwner={this.props.profileOwner}
                          toggleReset={this.toggleReset}
                          readingSubTypes={this.props.readingSubTypes}
                          userId={this.props.userId}
                          deletePermission={this.deletePermission}
                          approvePermission={this.approvePermission}
                          receivedFrom={false}
                          displayActiveList={this.state.displayActiveList}
                          displayCellularConnectivityList={
                            this.state.displayCellularConnectivityList
                          }
                          displayNeedToFollowUpList={this.state.displayNeedToFollowUpList}
                          displayHospitalAddmissionList={this.state.displayHospitalAddmissionList}
                          displayItIssuesList={this.state.displayItIssuesList}
                          displayFollowUpReminderList={this.state.displayFollowUpReminderList}
                          displayReminderSentList={this.state.displayReminderSentList}
                          displayWarningAdherence={this.state.displayWarningAdherence}
                        />
                        {/* <PatientList
                        usersGivenAccessMyReading={
                          // this.props.usersOfferedAccess
                          patientsListData
                        }
                        profileOwner={this.props.profileOwner}
                        toggleReset={this.toggleReset}
                        readingSubTypes={this.props.readingSubTypes}
                        userId={this.props.userId}
                        deletePermission={this.deletePermission}
                        approvePermission={this.approvePermission}
                        receivedFrom={false}
                        displayActiveList={this.state.displayActiveList}
                        displayCellularConnectivityList={
                          this.state.displayCellularConnectivityList
                        }
                      /> */}
                      </div>
                      </div>
                    )}

                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create a New Patient
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              {/* <div className="col-6">
                <NavLink
                  className="text-center d-block"
                  onClick={this.openInvite}
                >
                  <img
                    src="https://image.flaticon.com/icons/svg/201/201618.svg"
                    style={{ height: "180px" }}
                  />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    INVITE A USER
                  </span>
                  <small>Send a sign up link</small>
                </NavLink>
              </div> */}
              <div className="col-12 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleUser}
                >
                  <img src={addUsersImage} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    CREATE A NEW PATIENT ACCOUNT
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          {/* {!this.state.bday && (
            <div>
              Submitted Successfully Invitation is Sent to {this.state.email}
            </div>
          )} */}
          <div>Your action is successful</div>
        </SweetAlert>
        <SweetAlert
          warning
          showCancel
          show={this.state.showWarning}
          confirmBtnText="Yes, reset the password now!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.submitResetPassword}
          onCancel={this.submitReset}
        >
          This will change the password of these user
        </SweetAlert>
        <Modal
          isOpen={this.state.sharedmodal}
          toggle={this.toggleSharedModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleSharedModal}>
            Create a New Shared Account
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              <div className="col-12 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleShared}
                >
                  <img src={familyAccount} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    CREATE A NEW SHARED ACCOUNT
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
        <CreateSharedAccountModal
        modalUser={this.state.modalShared}
        toggleUser={this.toggleShared}
        errorPassword={this.state.errorPassword}
        submitUser={this.submitShared}
        handleInputChange={this.handleInputChange}
        handleRole={this.handleRole}
        />
        <ResetPassword
          modalReset={this.state.modalReset}
          toggleReset={this.toggleReset}
          fullname={this.state.resetFullname}
          errorPassword={this.state.errorPassword}
          submitReset={this.submitReset}
          handleInputChange={this.handleInputChange}
        />
        <CreateUserModal
          modalUser={this.state.modalUser}
          toggleUser={this.toggleUser}
          errorPassword={this.state.errorPassword}
          submitUser={this.submitUser}
          handleInputChange={this.handleInputChange}
          userDetails={this.state}
          handleRole={this.handleRole}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    usersOfferedAccessOrganization,
    allPatientCount,
    ongoingRequest,
    createPatientPermission
  } = careprovider;
  const { readingSubTypes } = auth;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    usersOfferedAccessOrganization,
    userId,
    profileOwner,
    allPatientCount,
    ongoingRequest,
    createPatientPermission
  };
};

export default connect(mapStateToProps)(AdminAnalytics);
