import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import RequiredSelect from "../../Rules/RequiredSelect";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Datetime from "react-datetime";

const verifyNumberInput = (value) => {
  const regex = /^[0-9]*$/; // Disallow Negative Number / Special Char / Char
  return regex.test(value);
};

const conditions = [
  { value: "between", label: "In Range" },
  { value: ">=", label: "Greater Than" },
  { value: "<=", label: "Less Than" },
];

const durations = [
  { value: "hour", label: "Hour" },
  { value: "day", label: "Day" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
];

const ExpectationModal = ({
  show,
  onClose,
  initialValues,
  handleSubmit,
  setIntialValues,
}) => {
  const [timezone, setTimeZone] = useState();
  const [inRange, setInRange] = useState(false);
  const [countTime, setCountTime] = useState(1);
  const [timezoneList, setTimezoneList] = useState([]);

  // State Form Redux
  const isCareProvider = useSelector((state) => state.user.userDetails.isCareProvider);

  const handleInputChange = (event) => {
    const target = event?.target;
    const { name, value } = target;
    const notifySelf = initialValues?.notifySelf;
    const notifyReadingOwner = initialValues?.notifyReadingOwner;
    const notifyStaff = initialValues?.notifyStaff;

    // Handled logic for checkbox
    if (target?.type === "checkbox") {
      let index;
      if (target.checked) {
        if (name === "notifySelf") {
          notifySelf.push(value);
        } else if (name === "notifyStaff") {
          notifyStaff.push(value);
        } else {
          notifyReadingOwner.push(value);
        }
      } else {
        if (name === "notifySelf") {
          index = notifySelf.indexOf(value);
          notifySelf.splice(index, 1);
        } else if (name === "notifyStaff") {
          index = notifyStaff.indexOf(value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      setIntialValues({
        ...initialValues,
        notifySelf: notifySelf,
        notifyReadingOwner: notifyReadingOwner,
        notifyStaff: notifyStaff,
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      if(Math.sign(value) == 0 || Math.sign(value) == 1){
        setIntialValues({
          ...initialValues,
          valueProperty: { ...initialValues.valueProperty, [name]: value },
        });
      }
      return;
    }
    setIntialValues({ ...initialValues, [name]: value });
  };

  const handleCondition = (selectedOption) => {
    if (initialValues?.ruleType == "Trending Violation") {
      setIntialValues({
        ...initialValues,
        repeatPer: selectedOption.value,
      });
    } else {
      setIntialValues({
        ...initialValues,
        condition: selectedOption.value,
        valueProperty: [],
      });

      if (selectedOption?.value === "between") setInRange(true);
      else setInRange(false);

    }
  };

  const handleTimezone = (selectedOption) => {
    setIntialValues({ ...initialValues, timezone: selectedOption.value });
    setTimeZone(selectedOption);
  };

  const handleDateChange = (event, i) => {
    const time = initialValues.time;
    time[i] = event.format("hh:mm A");
    setIntialValues({ ...initialValues, time: time });
  };

  const setTimeZoneList = () => {
    const timezoneList = moment.tz.names();
    const mapTimezones = _.map(timezoneList, (v) => {
    return { label: v, value: v } });
    setTimezoneList(mapTimezones);
  }

  useEffect(() => {
    setTimeZoneList(); // Set Time Zone
  }, [])

  useEffect(() => {
    
    // Update Value In Range
    if(initialValues?.condition && initialValues?.condition === "between"){
      setInRange(true);
    }else{
      setInRange(false);
    }

    // setting up the count
    if(initialValues?.time && initialValues?.time?.length > 0){
      setCountTime(initialValues?.time?.length);
    }

    // Setted Values for vital
    if (initialValues?.valueProperty && initialValues?.valueProperty?.[0]) {
      setIntialValues(prev => ({ ...prev, valueProperty: { ...prev.valueProperty[0] } }));
    }
    
  }, [ initialValues?.condition,  initialValues?.time, initialValues?.valueProperty ]);

  const timeElements = [];

  for (let i = 0; i < countTime; i++) {
    timeElements.push(
      <FormGroup key={i}>
        <Label for="exampleSelect">
          {initialValues?.ruleType == "Reminder"
            ? "Pick a Specific Time for Reminders"
            : "Pick a Specific Time"}
        </Label>
        <Row form>
          <Col md={11}>
            <Datetime
              className="custom-input"
              dateFormat={false}
              inputProps={{
                placeholder: "Input Desired Time",
                required: true,
              }}
              value={(initialValues?.time?.length > 0 && initialValues?.time[i]) || ""}
              onChange={(event) => handleDateChange(event, i)} // Handle change for each field
            />
          </Col>
          <Col md={1}>
            {i !== 0 && (
              <button
                type="button"
                className="btn btn-gradient-dark btn-rounded btn-icon"
                onClick={() => {
                  setCountTime((prev) => prev - 1);
                }} // Remove the time at this index
              >
                <i className="mdi mdi-delete" />
              </button>
            )}
          </Col>
        </Row>
      </FormGroup>
    );
  }

  return (
    <Modal isOpen={show} toggle={onClose}>
      <ModalHeader toggle={onClose}>Update Expectation</ModalHeader>
      <ModalBody className="bg-white">
        <Form onSubmit={handleSubmit}>
          {(initialValues?.ruleType === "Time Adherence" ||
            initialValues?.ruleType === "Adherence" ||
            initialValues?.ruleType === "Reminder" ||
            initialValues?.ruleType === "Trending Violation") && (
            <FormGroup>
              <Label for="exampleSelect">Select a Timezone</Label>
              <RequiredSelect
                SelectComponent={Select}
                value={timezoneList.find(
                  (tz) => tz.value === initialValues.timezone
                )}
                onChange={handleTimezone}
                options={timezoneList}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label for="exampleEmail">Expectation Name</Label>
            <Input
              type="text"
              name="name"
              value={initialValues?.name}
              id="exampleEmail"
              required={true}
              onChange={handleInputChange}
              placeholder="Expectation Name"
            />
          </FormGroup>

          <FormGroup>
            <Label for="exampleText">Notification Message (Patient)</Label>
            <Input
              type="textarea"
              name="message"
              value={initialValues?.message}
              onChange={handleInputChange}
              rows={10}
            />
          </FormGroup>

          <FormGroup>
            <Label for="exampleText">Notification Message (Careprovider)</Label>
            <Input
              type="textarea"
              value={initialValues?.messageCareprovider}
              name="messageCareprovider"
              onChange={handleInputChange}
              rows={10}
            />
          </FormGroup>

          {(initialValues?.ruleType === "Reminder" ||
            initialValues?.ruleType === "Time Adherence") && (
            <>
              {timeElements}
              <button
                type="button"
                className="btn btn-dark btn-sm btn-block mb-4"
                onClick={() => setCountTime((prev) => prev + 1)}
              >
                {initialValues?.ruleType == "Reminder"
                  ? "ADD MORE TIME FOR REMINDERS"
                  : "ADD MORE TIME TO COMPLY TO UPLOAD READINGS"}
              </button>
            </>
          )}

          {initialValues?.ruleType == "Adherence" && (
            <FormGroup>
              <Label for="exampleText">Times per Day</Label>
              <Input
                type="text"
                name="repeatPerDay"
                required={true}
                value={initialValues?.repeatPerDay || ''}
                onChange={(e) => {
                  if(verifyNumberInput(e?.target?.value)){
                     handleInputChange(e);
                  }
                }}
                rows={10}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          )}

          {initialValues?.ruleType === "Trending Violation" && (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">
                    Number of Times Violation Triggered
                  </Label>
                  <Input
                    type="text"
                    name="numberOfTimes"
                    value={initialValues?.numberOfTimes || ''}
                    onChange={(e) => {
                      if(verifyNumberInput(e?.target?.value)){
                         handleInputChange(e);
                      }
                    }}
                    placeholder="Number of Times"
                    onWheel={(e) => e.target.blur()}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleSelect">Every</Label>
                  <Select
                    required={true}
                    value={durations?.find((item) => item?.value == initialValues?.repeatPer)}
                    onChange={handleCondition}
                    options={durations}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          {initialValues?.ruleType?.toLowerCase() === "vitals" && (
            <>
              <FormGroup>
                <Label for="exampleSelect">Analyze By</Label>
                <RequiredSelect
                  SelectComponent={Select}
                  required={true}
                  value={conditions.find(
                    (tz) => tz.value === initialValues.condition
                  )}
                  onChange={handleCondition}
                  options={conditions}
                />
              </FormGroup>
              
              {/* If Range in Between */}
              {inRange && (
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Min Value</Label>
                      <Input
                        type="text"
                        name="minValue"
                        required={inRange}
                        value={initialValues?.valueProperty?.minValue || ''}
                        onChange={(e) => {
                          if(verifyNumberInput(e?.target?.value)){
                             handleInputChange(e);
                          }
                        }}
                        placeholder="Input Reading Min Value"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="examplePassword">Max Value</Label>
                      <Input
                        type="text"
                        value={initialValues?.valueProperty?.maxValue || ''}
                        name="maxValue"
                        required={inRange}
                        onChange={(e) => {
                          if(verifyNumberInput(e?.target?.value)){
                             handleInputChange(e);
                          }
                        }}
                        placeholder="Input Reading Max Value"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {/* In Range Input */}
              {!inRange && (
                <FormGroup>
                  <Label for="exampleText">Value</Label>
                  <Input
                    required={!inRange}
                    type="text"
                    name="value"
                    value={initialValues?.valueProperty?.value || ''}
                    onChange={(e) => {
                      if(verifyNumberInput(e?.target?.value)){
                         handleInputChange(e);
                      }
                    }}
                    placeholder="Input Reading Value"
                  />
                </FormGroup>
              )}
            </>
          )}

          {initialValues?.ruleType?.toLowerCase() === "weight" && (
            <FormGroup>
              <Label for="exampleText">Pound loss/gain per Day</Label>
              <Input
                type="text"
                name="gainLossPerDay"
                step="0.1"
                value={initialValues?.gainLossPerDay || ''}
                required={true}
                onChange={(e) => {
                  if(verifyNumberInput(e?.target?.value)){
                     handleInputChange(e);
                  }
                }}
                rows={10}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          )}
          {isCareProvider && (
            <FormGroup>
              <Label for="exampleCheckbox">Notify Me</Label>
              <div>
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline"
                  name="notifySelf"
                  onChange={handleInputChange}
                  value="inbox"
                  label="Inbox"
                  checked={initialValues?.notifySelf?.includes("inbox")}
                  inline
                />
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline2"
                  name="notifySelf"
                  onChange={handleInputChange}
                  label="SMS"
                  value="sms"
                  checked={initialValues?.notifySelf?.includes("sms")}
                  inline
                />
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline3"
                  name="notifySelf"
                  onChange={handleInputChange}
                  label="VOICE"
                  value="voice"
                  checked={initialValues?.notifySelf?.includes("voice")}
                  inline
                />
                <CustomInput
                  type="checkbox"
                  id="exampleCustomInline4"
                  name="notifySelf"
                  onChange={handleInputChange}
                  label="EMAIL"
                  value="email"
                  checked={initialValues?.notifySelf?.includes("email")}
                  inline
                />
              </div>
            </FormGroup>
          )}

          <FormGroup>
            {isCareProvider && (
              <Label for="exampleCheckbox">Notify Readings Owner</Label>
            )}
            <div>
              <CustomInput
                type="checkbox"
                id="exampleCustomInline6"
                name="notifyReadingOwner"
                onChange={handleInputChange}
                value="inbox"
                label="Inbox"
                checked={initialValues?.notifyReadingOwner?.includes("inbox")}
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline7"
                name="notifyReadingOwner"
                onChange={handleInputChange}
                checked={initialValues?.notifyReadingOwner?.includes("sms")}
                label="SMS"
                value="sms"
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline8"
                name="notifyReadingOwner"
                onChange={handleInputChange}
                label="VOICE"
                value="voice"
                checked={initialValues?.notifyReadingOwner?.includes("voice")}
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline9"
                name="notifyReadingOwner"
                onChange={handleInputChange}
                label="EMAIL"
                value="email"
                checked={initialValues?.notifyReadingOwner?.includes("email")}
                inline
              />
            </div>
          </FormGroup>

          <FormGroup>
            {isCareProvider && (
              <Label for="exampleCheckbox">Notify Staff</Label>
            )}
            <div>
              <CustomInput
                type="checkbox"
                id="exampleCustomInline10"
                name="notifyStaff"
                checked={initialValues?.notifyStaff?.includes("inbox")}
                onChange={handleInputChange}
                value="inbox"
                label="Inbox"
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline11"
                name="notifyStaff"
                onChange={handleInputChange}
                label="SMS"
                value="sms"
                checked={initialValues?.notifyStaff?.includes("sms")}
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline12"
                name="notifyStaff"
                onChange={handleInputChange}
                label="VOICE"
                value="voice"
                checked={initialValues?.notifyStaff?.includes("voice")}
                inline
              />
              <CustomInput
                type="checkbox"
                id="exampleCustomInline13"
                name="notifyStaff"
                onChange={handleInputChange}
                label="EMAIL"
                value="email"
                checked={initialValues?.notifyStaff?.includes("email")}
                inline
              />
            </div>
          </FormGroup>
          <button
            type="submit"
            className="btn btn-block btn-info save-click-cls"
          >
            <i className="mdi mdi-content-save"></i> Update
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ExpectationModal;
