import React from "react";
import { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import LottieLoader from "../../LottieLoader";
import lottieEmptyFile from "../../../files/animation/empty_new.json";
import moment from "moment";
import _ from "lodash";
import ExpectationModal from "./ExpectationModal";
import { toast } from "react-toastify";

// Helper Function
function toCapitalize(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

const initialVal = {
    name: "",
    message: "",
    messageCareprovider: "",
    condition: "",
    createdAt: "",
    notifyReadingOwner: [],
    notifySelf: [],
    notifyStaff: [],
    readingSubType: "",
    readingSubTypeId: "",
    readingType: {},
    ruleType: "",
    valueProperty: []
}

const ExpectationsList = ({ sortedArrayViewExpectations, userId, onUpdate }) => {
  
    const [showModal, setShowModal] = useState(false);
    const [initialValues, setIntialValues] = useState(initialVal);

    const handleSubmit = (e) => {
      try {
        e.preventDefault();

        if (initialValues?.notifyReadingOwner.length > 0 && initialValues?.message?.trim().length === 0) {
          toast.error(
            "A notification is checked in notify reading owner, please provide a message for the Patient"
          );
        } else if ((initialValues?.notifyStaff.length > 0 ||
          initialValues?.notifySelf.length > 0) && initialValues?.messageCareprovider?.trim().length === 0) {
          toast.error(
            "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
          );
          }
        else{
          let data = {};
          if(initialValues?.sharedUsers && initialValues?.sharedUsers?.length > 0 && initialValues?.sharedUsers?.includes(userId)){
            data = { ...initialValues, sharedUsers: [userId], patientId: userId };
          }else{
            data = { ...initialValues, sharedUsers: [], patientId: userId };
          }
          onUpdate(data);
          setTimeout(() => setShowModal(false), 1000)
        }
      } catch (error) {
        console.log(error)
      }
    }

    return (
    <>
      <div className="care-plan-header-background" style={{ marginTop: 25 }}>
        <Row>
          <Col sm="12" className="center-label remove-table-padding">
            <h3 className="text-center title-device" style={{ marginLeft: 25 }}>
              <i class="mdi mdi-marker-check"></i> Prescribed Expectations
            </h3>
          </Col>
        </Row>
      </div>
      <div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="id"
          bordered={true}
          hover={true}
          condensed={true}
          noDataIndication={() => (
            <div>
              <LottieLoader
                loadLoop={false}
                autoplayLoop={true}
                source={lottieEmptyFile}
                Lheight={200}
                Lwidth={200}
                titleSmall={"Sorry! No prescribed expectations found :("}
                subTitleSmall={"We can't find any prescribed expectations."}
              />
            </div>
          )}
          data={sortedArrayViewExpectations}
          headerClasses="header-class-care-plan-table"
          columns={[
            {
              dataField: "name",
              text: "Name",
              sort: true,
              formatter: (cell, row) => {
                return <div>{row.name}</div>;
              },
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
            },
            {
              dataField: "createdBy.lname",
              text: "Created By",
              sort: true,
              headerStyle: (colum, colIndex) => {
                return {
                  width: "200px",
                  textAlign: "left",
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                if (!row.createdBy) {
                  return <div className="text-left">-</div>;
                }
                return (
                  <div className="text-left">
                    {toCapitalize(row.createdBy.lname)}{" "}
                    {toCapitalize(row.createdBy.fname)}
                  </div>
                );
              },
            },
            {
              dataField: "updatedBy.lname",
              text: "Last Modify By",
              sort: true,
              headerStyle: (colum, colIndex) => {
                return {
                  width: "200px",
                  textAlign: "left",
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                if (!row?.updatedBy) {
                  return <div className="text-center font-weight-bold">-</div>;
                }
                return (
                  <div className="text-left">
                    {toCapitalize(row?.updatedBy?.lname || "")}{" "}
                    {toCapitalize(row?.updatedBy?.fname || "")}
                  </div>
                );
              },
            },
            {
              dataField: "ruleType",
              text: "Expectation Type",
              sort: true,
              headerStyle: (colum, colIndex) => {
                return {
                  textAlign: "left",
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                return <div className="text-center">{row.ruleType}</div>;
              },
            },
            {
              dataField: "readingType.description",
              text: "Reading Type",
              sort: true,
              formatter: (cell, row) => {
                if (row.readingSubType) {
                  return (
                    <div className="text-left">
                      {row.readingType.description}
                    </div>
                  );
                } else {
                  return <div className="text-left">-</div>;
                }
              },
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
            },
            {
              dataField: "readingSubType",
              text: "Reading Sub Type",
              sort: true,
              formatter: (cell, row) => {
                if (row.readingSubType) {
                  return <div className="text-left">{row.readingSubType}</div>;
                } else {
                  return <div className="text-left">-</div>;
                }
              },
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
            },
            {
              dataField: "condition",
              text: "Expectation",
              formatter: (cell, row) => {
                switch (row.ruleType) {
                  case "Vitals":
                    if (row.condition == "<=") {
                      return "Less than or equal to";
                    } else if (row.condition == ">=") {
                      return "Greater than or equal to";
                    } else if (row.condition == "between") {
                      return "Between";
                    } else if (
                      row.name.includes("Daily") ||
                      row.name.includes("1 X")
                    ) {
                      return "1 x per day";
                    } else if (row.name.includes("2 X")) {
                      return "2 x per day";
                    } else {
                      return <div className="text-center">-</div>;
                    }
                    break;
                  case "Adherence":
                    return row.repeatPerDay && row.repeatPerDay == 1
                      ? "Daily"
                      : row.repeatPerDay + " Days";
                  case "Trending Violation":
                    return (
                      row.numberOfTimes +
                      " Times With In " +
                      toCapitalize(row.repeatPer)
                    );
                  case "Weight":
                    return row.gainLossPerDay + " Per Day";
                  case "Reminder":
                  case "Time Adherence":
                    if (row.time && _.size(row.time) > 0) {
                      let text = [];
                      _.map(row.time, (model, index) => {
                        if (_.size(row.time) - 1 == index) {
                          text.push(<span>{model}</span>);
                        } else {
                          text.push(<span>{model}, </span>);
                        }
                      });
                      return <div> {text}</div>;
                    } else {
                      return <div className="text-center">-</div>;
                    }
                  default:
                    if (row.condition == "<=") {
                      return "Less than or equal to";
                    } else if (row.condition == ">=") {
                      return "Greater than or equal to";
                    } else if (
                      row.name.includes("Daily") ||
                      row.name.includes("1 X")
                    ) {
                      return "1 x per day";
                    } else if (row.name.includes("2 X")) {
                      return "2 x per day";
                    }
                    return row.condition;
                }
              },
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
            },
            {
              dataField: "valueProperty",
              text: "Value",
              headerStyle: (colum, colIndex) => {
                return {
                  width: "100px",
                  textAlign: "center",
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                if (
                  row.valueProperty &&
                  row["valueProperty"] !== undefined &&
                  row.valueProperty.length > 0
                ) {
                  if (row.condition != "between") {
                    return (
                      <div className="text-center">
                        {row.valueProperty[0].value}
                      </div>
                    );
                  } else if (row.condition == "between") {
                    return (
                      <div className="text-center">
                        {row.valueProperty[0].minValue} and{" "}
                        {row.valueProperty[0].maxValue}
                      </div>
                    );
                  } else {
                    return <div className="text-center">-</div>;
                  }
                } else {
                  return <div className="text-center">-</div>;
                }
              },
            },
            {
              dataField: "",
              text: "Notifications",
              sort: false,
              headerStyle: (colum, colIndex) => {
                return {
                  width: "320px",
                  textAlign: "center",
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                if (
                  (row.notifyReadingOwner &&
                    row.notifyReadingOwner.length > 0) ||
                  (row.notifySelf && row.notifySelf.length) > 0 ||
                  (row.notifyStaff && row.notifyStaff.length) > 0
                ) {
                  return (
                    <div className="text-center">
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th className="text-center">CareProvider</th>
                          <th className="text-center">Staff</th>
                          <th className="text-center">Patient</th>
                        </tr>
                        <tr>
                          <td>
                            {row.notifySelf && row.notifySelf.length > 0 && (
                              <div className="text-center">
                                {_.map(_.uniqBy(row.notifySelf), (v) =>
                                  _.startCase(v)
                                ).toString()}{" "}
                              </div>
                            )}
                            {!row.notifySelf ||
                              (row.notifySelf.length == 0 && (
                                <div className="text-center"> - </div>
                              ))}
                          </td>
                          <td>
                            {row.notifyStaff && row.notifyStaff.length > 0 && (
                              <div className="text-center">
                                {_.map(_.uniqBy(row.notifyStaff), (v) =>
                                  _.startCase(v)
                                ).toString()}{" "}
                              </div>
                            )}
                            {!row.notifyStaff ||
                              (row.notifyStaff.length == 0 && (
                                <div className="text-center"> - </div>
                              ))}
                          </td>
                          <td>
                            {row.notifyReadingOwner &&
                              row.notifyReadingOwner.length > 0 && (
                                <div className="text-center">
                                  {_.map(
                                    _.uniqBy(row.notifyReadingOwner),
                                    (v) => _.startCase(v)
                                  ).toString()}{" "}
                                </div>
                              )}
                            {!row.notifyReadingOwner ||
                              (row.notifyReadingOwner.length == 0 && (
                                <div className="text-center"> - </div>
                              ))}
                          </td>
                        </tr>
                      </table>
                    </div>
                  );
                } else {
                  return <div className="text-center">-</div>;
                }
              },
            },
            {
              dataField: "updatedAt",
              text: "Last Updated",
              sort: true,
              formatter: (cell, row) => {
                if (row.updatedAt) {
                  return moment(row.updatedAt)
                    .tz(moment.tz.guess())
                    .format("MMMM DD, YYYY");
                } else {
                  return "NONE";
                }
              },
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
            },
            {
              text: "Action",
              headerStyle: (colum, colIndex) => {
                return {
                  padding: "16px",
                  fontSize: "16px",
                  color: "#070707",
                };
              },
              formatter: (cell, row) => {
                return (
                  <div>
                    <Button className="edit-click-condition" onClick={() => {
                        setShowModal(true);
                        setIntialValues(row)
                    }}><i className="mdi mdi-pencil"></i> Edit </Button>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
      <ExpectationModal 
          handleSubmit={handleSubmit} 
          show={showModal} 
          setIntialValues={setIntialValues}
          onClose={() => setShowModal(false)} 
          initialValues={initialValues}
      />
    </>
  );
};

export default ExpectationsList;
