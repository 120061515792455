import classnames from "classnames";
import CCMList from "components/CCM/CCMList";
import Link from "components/ExtendLink";
import careproviderpatient from "files/careproviderpatient.png";
import icCondtion from "files/ic_condtion.png";
import expectationImages from "files/ic_expectations.svg";
import staff from "files/staff.png";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
  Row
} from "reactstrap";
import fullPatientImage from "../../../files/full_input_patient.png";
import quickPatientImage from "../../../files/quick_patient.png";

import ReactLoading from "react-loading";
import ProfileDetail from "components/Settings/ProfileDetail";
import moment from "moment";
import PatientList from "../../Analytics/PatientList";
import Condition from "./Condition";
import CreateStaffModal from "./CreateStaffModal";
import CreateUserModal from "./CreateUserModal";
import CreateUserQuickModal from "./CreateUserQuickModal";
import Expectations from "./Expectations/";
import PickRuleModal from "./Expectations/PickRuleModal";
import StaffList from "./StaffList";
const _ = require("lodash");

class AdminCareProviderDashboard extends Component {
  constructor(props) {
    super(props);
    this.stopTimer = this.stopTimer.bind(this);
    this.state = {
      getUserStatistics: "",
      activeTab: "4",
      processSubTypes: "",
      dateRange: 7,
      endDate: moment().add(1, "d").format("YYYY-MM-DD"),
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      startDateForPM: moment().startOf("month").format("MMMM DD, YYYY"),
      endDateForPM: moment().endOf("month").format("MMMM DD, YYYY"),
      currentUserId: "",

      // Patient Creation fields
      modal: false,
      modalUser: false,
      modalQuickUser: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      fax: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      subOrganization: "",
      showAlertSuccess: false,
      errorPassword: false,
      conditions: [],
      insuranceProvider: "",
      enrolledPrograms: "",
      loading: true,

      //Staff creation fields
      staffModal: false,

      // Expectation creation fields
      expectationCreationModal: false,
      role: "",
      otherRole: undefined,
      isOtherRole: false,
    };
    this.dateRange = this.dateRange.bind(this);
    this.getUserStatistics = this.getUserStatistics.bind(this);
    this.processSubTypes = this.processSubTypes.bind(this);
    this.userInputChange = this.userInputChange.bind(this);
    this.expectationInputChange = this.expectationInputChange.bind(this);

    // Patient Creation binds
    this.togglePatientModal = this.togglePatientModal.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.toggleUserQuick = this.toggleUserQuick.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeCondition =
      this.handleInputChangeCondition.bind(this);
    this.resetForm = this.resetForm.bind(this);

    // Staff Creation binds
    this.toggleStaff = this.toggleStaff.bind(this);
    this.submitStaff = this.submitStaff.bind(this);

    // Expectation Creation binds
    this.toggleExpectationCreationModal =
      this.toggleExpectationCreationModal.bind(this);
    this.handleRole = this.handleRole.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleRole(e){
    if(e.target.name != 'otherRole'){
      this.setState({ role: e.target.value, otherRole: undefined });
      return;
    }
    if(this.state.role == 'Other' && e.target.name == 'otherRole'){
      this.setState({ otherRole: e.target.value });
    }else if(this.state.role != 'Other' && e.target.name != 'otherRole'){
      this.setState({ otherRole: undefined });
    }
  }
  /*
   * Patient Creation functions
   */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  handleInputChangeCondition(e) {
    const conditions = _.map(e, "_id");
    this.setState({ conditions: conditions });
  }
  resetForm() {
    this.setState({
      modal: false,
      modalUser: false,
      modalQuickUser: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      fax: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      subOrganization: "",
      gender: "Male",
      role: "",
      conditions: [],
      insuranceProvider: "",
      enrolledPrograms: "",

      //Staff creation fields
      staffModal: false,

      // Expectation creation fields
      expectationCreationModal: false,
      otherRole: undefined,
    });
    this.props.dispatch({
      type: "GENERIC_ERROR"
    });
    this.props.dispatch({
      type: "DO_GET_USERS_LIST",
      payload: { careproviderId: this.state.currentUserId }
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDER_STAFF",
      payload: { careproviderId: this.state.currentUserId }
    });
  }
  submitUser(e) {
    e.preventDefault();
    const cleanUser = _.omit(this.state, [
      "activeTab",
      "modal",
      "modalUser",
      "modalQuickUser",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
      "getUserStatistics",
      "processSubTypes",
      "dateRange",
      "endDate",
      "startDate",
      "startDateForPM",
      "endDateForPM",
      "currentUserId",
      "staffModal",
      "expectationCreationModal",
      "loading"
    ]);
    this.props.dispatch({
      type: "DO_SAVE_USER",
      payload: {
        cleanUser: cleanUser,
        careproviderId: this.state.currentUserId
      }
    });
  }
  toggleUser() {
    this.setState({
      modalUser: !this.state.modalUser
    });
  }
  toggleUserQuick() {
    this.setState({
      modalQuickUser: !this.state.modalQuickUser
    });
  }
  /*
   * End Patient Creation functions
   */

  /*
   * Staff Creation Functions
   */
  toggleStaff() {
    this.setState({
      staffModal: !this.state.staffModal
    });
  }
  handleKeyDown(e){
    const inputField = e.target;
    const inputValue = inputField.value;
    const caretPosition = inputField.selectionStart;
    if (e.keyCode === 32) {
      // Check if it's the first character and if there are no non-space characters before it
      if (caretPosition === 0 || /^\s+$/.test(inputValue.substring(0, caretPosition))) {
        e.preventDefault();
      }
    }
  };
  submitStaff(e) {
    e.preventDefault();
    this.setState({
      role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
      isOtherRole: this.state.role != 'Other' ? false : true,
    }, () => {
    const cleanStaff = _.omit(this.state, [
      "activeTab",
      "modal",
      "modalStaff",
      "emailOffer",
      "confirmpassword",
      "emailAccess",
      "showAlertSuccess",
      "errorPassword",
      "modalUser",
      "modalQuickUser",
      "getUserStatistics",
      "processSubTypes",
      "dateRange",
      "endDate",
      "startDate",
      "startDateForPM",
      "endDateForPM",
      "currentUserId",
      "staffModal",
      "expectationCreationModal",
      "loading",
      "otherRole"
    ]);
    this.props.dispatch({
      type: "DO_SAVE_STAFF",
      payload: {
        cleanStaff: cleanStaff,
        careproviderId: this.state.currentUserId
      }
    });
    });
  }
  /*
   * End Staff Creation Functions
   */

  /*
   * Create Expectation Functions
   */

  toggleExpectationCreationModal() {
    this.setState({
      expectationCreationModal: !this.state.expectationCreationModal
    });
  }

  /*
   * End Create Expectation Functions
   */

  userInputChange(event, staffId) {
    const target = event.target;
    const isCheck = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (isCheck) {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
        payload: { staffId: staffId, isAdd: true, users: [target.name] }
      });
    } else {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
        payload: { staffId: staffId, isAdd: false, user: target.name }
      });
    }
    setTimeout(() => {
      let id;
      if (this.props.owner) {
        id = this.props.owner;
      } else {
        id = this.props.match.params.id;
      }
      this.props.dispatch({
        type: "DO_GET_CAREPROVIDER_STAFF",
        payload: { careproviderId: id }
      });
    }, 200);
    // this.setState({
    //   [name]: value
    // });
  }
  expectationInputChange(event, staffId, expectation) {
    const target = event.target;
    const isCheck = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (isCheck) {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_EXPECTATION",
        payload: {
          staffId: staffId,
          isAdd: true,
          expectationId: expectation._id,
          expectationType: expectation.ruleType
        }
      });
    } else {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_EXPECTATION",
        payload: {
          staffId: staffId,
          isAdd: false,
          expectationId: expectation._id,
          expectationType: expectation.ruleType
        }
      });
    }
    setTimeout(() => {
      let id;
      if (this.props.owner) {
        id = this.props.owner;
      } else {
        id = this.props.match.params.id;
      }
      this.props.dispatch({
        type: "DO_GET_CAREPROVIDER_STAFF",
        payload: { careproviderId: id }
      });
    }, 200);
    // this.setState({
    //   [name]: value
    // });
  }
  togglePatientModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  getUserStatistics() {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const finalId = id ? id : this.props.userId;
    const userAccess = this.props.assignedUsersList;
    const userStat = _.filter(userAccess, {
      receivedFrom: { _id: id }
    });
    this.setState({
      getUserStatistics: userStat,
      userProfile: userStat[0].receivedFrom
    });
    return userStat[0];
  }
  processSubTypes() {
    const readingSubTypes = this.props.readingSubTypes;
    const userStat = this.getUserStatistics();
    const newTypes = readingSubTypes.map((value, i) => {
      const readings = _.filter(userStat.readingsSevenDays, {
        readingType: value.reading
      });
      if (readings.length > 0) {
        return value;
      }
    });
    const removeUndefined = _.reject(newTypes, _.isNil);

    this.setState({
      processSubTypes: removeUndefined
    });
  }

  dynamicColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);

    return "rgb(" + r + "," + g + "," + b + ")";
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "DO_GET_CREATE_PATIENT_PERMISSION"
    });
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
      const { start, end } = this.props.match.params;
      if (start) {
        this.setState({
          dateRange: "custom",
          startDate: moment(start).format("YYYY-MM-DD"),
          endDate: moment(end).format("YYYY-MM-DD")
        });
      }

      this.setState({ currentUserId: id });
    }
    this.props.dispatch({
      type: "DO_GET_USERS_LIST",
      payload: { careproviderId: id }
    });
    this.props.dispatch({
      type: "DO_GET_CAREPROVIDER_STAFF",
      payload: { careproviderId: id }
    });

    this.props.dispatch({
      type: "DO_GET_PROFILE_PATIENT",
      payload: { user: id, isClient: true }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.assignedUsersList !== prevProps.assignedUsersList) {
      this.setState({ loading: false });
    }
  }

  stopTimer(onConfirm) {
    this.props.dispatch({
      type: "START_TIMER",
      payload: false
    });
    onConfirm();
  }
  dateRange(event) {
    let id;
    if (this.props.owner) {
      id = this.props.owner;
    } else {
      id = this.props.match.params.id;
    }
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let dateTo;
    dateTo = moment().add(1, "d").format();

    let dateFrom;
    if (name === "dateRange") {
      if (value == 7) {
        dateFrom = moment().subtract(7, "d").format();
      }
      if (value == 1) {
        dateFrom = moment().subtract(1, "M").format();
      }
      if (value == 6) {
        dateFrom = moment().subtract(6, "M").format();
      }
      this.setState({ startDate: dateFrom, endDate: dateTo, [name]: value });
      // if (this.props.owner) {
      //   this.props.dispatch({
      //     type: "DO_GET_USER_READINGS",
      //     payload: {
      //       user: id,
      //       startDate: dateFrom,
      //       endDate: dateTo,
      //       patientPath: true
      //     }
      //   });
      // } else {
      //   this.props.dispatch({
      //     type: "DO_GET_USER_READINGS",
      //     payload: { user: id, startDate: dateFrom, endDate: dateTo }
      //   });
      // }
    } else {
      this.setState({ [name]: value });
    }
  }
  componentWillUnmount() {
    if (!this.props.owner) {
      this.props.dispatch({
        type: "DO_REMOVE_PROFILE_DATA"
      });
    }

    this.props.dispatch({
      type: "START_TIMER",
      payload: false
    });
  }

  render() {
    const removeNotApprove = _.orderBy(
      _.orderBy(this.props.assignedUsersList, "isActive", "desc"),
      "fname",
      "asc"
    );
    const onlyActivePatients = _.orderBy(
      _.filter(this.props.assignedUsersList, "isActive"),
      "fname",
      "asc"
    );
    if (this.props.profile)
      return (
        <div>
          <div className="row">
            {!this.props.hideBackButton && (
              <span className={`align-items-center mb-4`}>
                <Link
                  to="/account-management"
                  className="text-white mr-auto btn btn-gradient-dark btn-icon-text"
                >
                  <i className="mdi mdi-arrow-left btn-icon-prepend" />
                  Back
                </Link>
              </span>
            )}

            <div className="col-12 grid-margin">
              <section>
                <div className="sttabs tabs-style-iconbox">
                  <nav>
                    <ul>
                      <li
                        className={classnames({
                          tabcurrent: this.state.activeTab === "4"
                        })}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        <a className="char font-bold urgent-tabs">
                          {" "}
                          <img
                            src={careproviderpatient}
                            width="80px"
                            className="img-circle"
                            alt
                          />
                          <br />
                          <span>
                            {" "}
                            <br /> PATIENTS
                          </span>
                        </a>
                      </li>
                      {!this.props.profile.isStaff && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            {" "}
                            <img
                              src={staff}
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> STAFF
                            </span>
                          </a>
                        </li>
                      )}
                      {!this.props.profile.isStaff && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            {" "}
                            <img
                              src={expectationImages}
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> EXPECTATIONS
                            </span>
                          </a>
                        </li>
                      )}
                      {!this.props.profile.isStaff && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "6"
                          })}
                          onClick={() => {
                            this.toggle("6");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            {" "}
                            <img
                              src={icCondtion}
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> CONDITIONS
                            </span>
                          </a>
                        </li>
                      )}
                      {!this.props.hideProfile && (
                        <li
                          className={classnames({
                            tabcurrent: this.state.activeTab === "5"
                          })}
                          onClick={() => {
                            this.toggle("5");
                          }}
                        >
                          <a className="char font-bold urgent-tabs">
                            {" "}
                            <img
                              src="https://cdn0.iconfinder.com/data/icons/education-flat-icons-set/128/student-identity-badge-512.png"
                              width="80px"
                              className="img-circle"
                              alt
                            />
                            <br />
                            <span>
                              {" "}
                              <br /> PROFILE
                            </span>
                          </a>
                        </li>
                      )}
                    </ul>
                  </nav>
                  <div className="content-wrap">
                    {!this.props.profile.isStaff && (
                      <section
                        id="section-iconbox-1"
                        className={classnames({
                          contentcurrent: this.state.activeTab === "1"
                        })}
                      >
                        <h1 className="text-center mb-4">
                          {this.props.profile.fname} {this.props.profile.lname}{" "}
                          Staff
                        </h1>

                        <a
                          target="_blank"
                          onClick={this.toggleStaff}
                          className="text-white ml-auto btn btn-info btn-rounded btn-fw"
                        >
                          Create a New Staff
                        </a>

                        {this.props.careProviderStaff && (
                          <StaffList
                            usersOfferedAccess={onlyActivePatients}
                            expectations={this.props.careProviderExpectations}
                            deleteInvitation={true}
                            staff={this.props.careProviderStaff}
                            userInputChange={this.userInputChange}
                            expectationInputChange={this.expectationInputChange}
                          />
                        )}

                        <CreateStaffModal
                          modalStaff={this.state.staffModal}
                          toggleStaff={this.toggleStaff}
                          errorPassword={this.state.errorPassword}
                          submitStaff={this.submitStaff}
                          handleInputChange={this.handleInputChange}
                          handleRole = {this.handleRole}
                          role={this.state.role}
                          handleKeyDown={this.handleKeyDown}
                        />
                      </section>
                    )}
                    <section
                      id="section-iconbox-2"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "2"
                      })}
                    >
                      <h1 className="text-center mb-4">
                        {this.props.profile.fname} {this.props.profile.lname}{" "}
                        Expectations
                      </h1>

                      <PickRuleModal
                        modal={this.state.expectationCreationModal}
                        toggleModal={this.toggleExpectationCreationModal}
                        name={this.props.match.params.name}
                        id={this.state.currentUserId}
                      />
                      <Expectations />
                    </section>
                    <section
                      id="section-iconbox-3"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "3"
                      })}
                    >
                      <Row className="header-device-list">
                        <Col sm="6" className="center-label remove-table-padding">
                          <h3 className="text-center title-device">
                            <i class="mdi mdi-account"></i> PM Sessions ({this.props.profile.fname} {this.props.profile.lname})
                          </h3>
                        </Col>
                        <Col sm="6" className="remove-table-padding right-row">
                          <h3 className="text-center title-device right-row">
                          <i class="mdi mdi-filter"></i> Filter : {this.state.month} {this.state.year}
                          </h3>
                        </Col>
                      </Row>
                      <div className={`text-center col-md-6 mx-auto mt-5`}>
                        <h5 className="mb-4">
                          Pick Date Range to Count the Grand Total of PM
                          SESSIONS{" "}
                        </h5>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="exampleEmail">Start Date</Label>
                              <Input
                                type="date"
                                name="startDateForPM"
                                onChange={this.dateRange}
                                value={this.state.startDateForPM}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="examplePassword">End Date</Label>
                              <Input
                                type="date"
                                name="endDateForPM"
                                onChange={this.dateRange}
                                value={this.state.endDateForPM}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      {this.props.pmRecords && (
                        <CCMList
                          pmRecords={this.props.pmRecords}
                          userId={this.props.userId}
                          pleaseExpand={true}
                          startDate={this.state.startDateForPM}
                          endDate={this.state.endDateForPM}
                        />
                      )}
                    </section>
                    <section
                      id="section-iconbox-4"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "4"
                      })}
                    >
                      <h1 className="text-center mb-4">
                        {this.props.profile.fname} {this.props.profile.lname}{" "}
                        Patients
                      </h1>
                      {!this.props.profile.isStaff && (
                        <>
                          {this.props.createPatientPermission && (
                          <a
                            target="_blank"
                            onClick={this.togglePatientModal}
                            className="text-white ml-auto btn btn-info btn-rounded btn-fw"
                          >
                            Create a New Patient
                          </a>
                          )}
                          {/*<a
                            target="_blank"
                            onClick={() => {
                              let id;
                              if (this.props.owner) {
                                id = this.props.owner;
                              } else {
                                id = this.props.match.params.id;
                              }
                              this.props.dispatch({
                                type: "DO_SEND_PM_FAXES",
                                payload: { careproviderId: id }
                              });
                            }}
                            className="text-white ml-auto btn btn-info btn-rounded btn-fw"
                          >
                            Test Fax.plus via GET background
                          </a>*/}
                        </>
                      )}
                      {/*<Notes
                        hideNotesButton={this.props.careproviderPatients}
                        currentUserId={this.props.profile._id}
                      />*/}
                      {this.state.loading && (
                        <ReactLoading className="mx-auto" color={"#007bff"} />
                      )}
                      {!this.state.loading && this.props.assignedUsersList && (
                        <PatientList
                          usersGivenAccessMyReading={removeNotApprove}
                          profileOwner={this.props.profileOwner}
                          readingSubTypes={this.props.readingSubTypes}
                          userId={this.props.userId}
                          deletePermission={this.deletePermission}
                          approvePermission={this.approvePermission}
                          receivedFrom={false}
                        />
                      )}

                      <Modal
                        isOpen={this.state.modal}
                        toggle={this.togglePatientModal}
                        className={this.props.className}
                      >
                        <ModalHeader toggle={this.togglePatientModal}>
                          Create a New Patient
                        </ModalHeader>
                        <ModalBody style={{ backgroundColor: "white" }}>
                          <h5 className="text-center" />
                          <div className="row">
                            <div className="col-12 mx-auto">
                              <NavLink
                                className="text-center d-block"
                                onClick={this.toggleUserQuick}
                              >
                                <img
                                  src={quickPatientImage}
                                  style={{ height: "180px" }}
                                />
                                <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                                  QUICK PATIENT CREATION
                                </span>
                                <small>You will input only essentials</small>
                              </NavLink>
                            </div>
                            <div className="col-6 mx-auto">
                              <NavLink
                                className="text-center d-block"
                                onClick={this.toggleUser}
                              >
                                <img
                                  src={fullPatientImage}
                                  style={{ height: "180px" }}
                                />
                                <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                                  FULL PATIENT CREATION
                                </span>
                                <small>You will input everything</small>
                              </NavLink>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                      {this.state.currentUserId &&
                        this.state.activeTab === "4" && (
                          <CreateUserModal
                            modalUser={this.state.modalUser}
                            toggleUser={this.toggleUser}
                            errorPassword={this.state.errorPassword}
                            submitUser={this.submitUser}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeCondition={
                              this.handleInputChangeCondition
                            }
                            careproviderId={this.state.currentUserId}
                            handleRole = {this.handleRole}
                          />
                        )}
                      {this.state.currentUserId &&
                        this.state.activeTab === "4" && (
                          <CreateUserQuickModal
                            modalQuickUser={this.state.modalQuickUser}
                            toggleUserQuick={this.toggleUserQuick}
                            errorPassword={this.state.errorPassword}
                            submitUser={this.submitUser}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeCondition={
                              this.handleInputChangeCondition
                            }
                            careproviderId={this.state.currentUserId}
                            handleRole = {this.handleRole}
                          />
                        )}
                    </section>
                    <section
                      id="section-iconbox-5"
                      className={classnames({
                        contentcurrent: this.state.activeTab === "5"
                      })}
                    >
                      {this.props.profile && this.state.activeTab === "5" && (
                        <Row>
                          <Col md={8} className="mx-auto">
                            <h1 className="text-center mb-4">
                              {this.props.profile.fname}{" "}
                              {this.props.profile.lname} Profile
                            </h1>
                            <ProfileDetail
                              hideTitle
                              enableUserEdit={false}
                              isClient={true}
                              currentUserId={this.props.profile._id}
                            />
                          </Col>
                          {/* enableUserEdit={this.props.owner ? false : true} */}

                          {/* <Col md={8}>
                            <h1 className="text-center mb-4">
                              {this.props.profile.fname}{" "}
                              {this.props.profile.lname} Devices
                            </h1>
                            <Devices
                              hideTitle
                              patient="gwapo"
                              currentUserId={this.props.profile._id}
                              deviceList={this.props.profile.devices}
                            />
                          </Col> */}
                        </Row>
                      )}
                    </section>
                    {!this.props.profile.isStaff && (
                      <section
                        id="section-iconbox-1"
                        className={classnames({
                          contentcurrent: this.state.activeTab === "6"
                        })}
                      >
                        <Condition />
                      </section>
                    )}
                  </div>
                  {/* /content */}
                </div>
                {/* /tabs */}
              </section>
            </div>
          </div>

          <SweetAlert
            success
            title="Success!"
            show={this.props.isGenericSuccess}
            onConfirm={this.resetForm}
            confirmBtnBsStyle="info"
          >
            <div>Created Successfully</div>
          </SweetAlert>

          <SweetAlert
            error
            title={this.props.genericErrorMessage.message}
            show={this.props.isGenericError}
            onConfirm={() => {
              this.props.dispatch({
                type: "GENERIC_ERROR"
              });
            }}
            confirmBtnBsStyle="danger"
            timeout={1000}
          >
            Error Code: {this.props.genericErrorMessage.error}
          </SweetAlert>
        </div>
      );
    return <div />;
  }
}
const mapStateToProps = ({ careprovider, user, auth, client }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    timerStarts,
    pmRecords,
    usersViolations,
    readings,
    assignedUsersList,
    careProviderStaff,
    careProviderExpectations,
    authenticationToken,
    createPatientPermission
  } = careprovider;
  const { readingSubTypes, readingType } = auth;
  const { careproviderPatients } = client;
  const userId = user.userDetails._id;
  const profile = user.profile;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    readingType,
    userId,
    usersViolations,
    pmRecords,
    timerStarts,
    profile,
    readings,
    careproviderPatients,
    careProviderStaff,
    assignedUsersList,
    careProviderExpectations,
    authenticationToken,
    createPatientPermission
  };
};

export default connect(mapStateToProps)(AdminCareProviderDashboard);
