import React, { useState } from "react";
import { connect } from "react-redux";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import UserName from "../Reusable/UserName";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { Badge, Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import moment from "moment";
const _ = require("lodash");

function fullname(cell, row) {
  if (row) {
    return <UserName user={row} />;
  } else {
    return '-'
  }
}

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {"   "}Showing {from} to {to} of {size} Results
  </span>
);
const VersionReport = (props) => {
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUpdateNotificationType, setUpdateNotificationType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [messageError, setMessageError] = useState('');
  const [formError, setFormError] = useState(null);
  const [notificationSubject, setNotificationSubject] = useState('');

  const platformVersion = {}
  if (props.versionReports.latestVersions && props.versionReports.latestVersions.length > 0) {
    props.versionReports.latestVersions.map((item) => {
      if (item.systemName.toLowerCase() == 'ios' && item.isNewApp) {
        platformVersion.iosNewAppVersion = item.latestVersion
      } else if (item.systemName.toLowerCase() == 'android' && item.isNewApp) {
        platformVersion.androidNewAppVersion = item.latestVersion
      } else if (item.systemName.toLowerCase() == 'ios' && !item.isNewApp) {
        platformVersion.iosOldAppVersion = item.latestVersion
      } else if (item.systemName.toLowerCase() == 'android' && !item.isNewApp) {
        platformVersion.androidOldVersion = item.latestVersion
      }
    })
  }
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No users found in the listing for this version."}
        // subTitle={"No users found in the listing for this version."}
        />
      )}
    </div>
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedUpdateNotificationType) {
      setFormError('Please select a notification type.');
      return;
    }
    if (selectedUpdateNotificationType === 'other' && notificationMessage.length < 5) {
      setMessageError('Message must be at least 5 characters.');
      return;
    }
    if (selectedUser) {
      props.dispatch({
        type: "DO_SENT_UPDATE_APP_NOTIFICATION",
        payload: {
          userId: selectedUser && selectedUser.user && selectedUser.user._id,
          message: notificationMessage,
          notificationType: selectedUpdateNotificationType,
          subject : notificationSubject
        }
      });
      setShowUpdateModel(false);
      setSelectedUser(null);
    } else {
      alert('Something went wrong, Please try again!')
      setShowUpdateModel(false);
      setSelectedUser(null);
    }
    setNotificationMessage(null);
    setUpdateNotificationType(null);
    setFormError(null);
    setNotificationSubject(null)
  }
  const handlesendNotificationTypeChange = (event) => {
  if(event && event.target.value == 'updateNotification'){
  setUpdateNotificationType('updateNotification');
  setFormError(null);

  }else if(event && event.target.value == 'other'){
  setUpdateNotificationType('other');
  setFormError(null);
  }else{
  setUpdateNotificationType(null);
  }
  }
  const handleMessageChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if(name == 'subject'){
      setNotificationSubject(value);
    }else{
    if(value){
     setNotificationMessage(value)
    }
    if (selectedUpdateNotificationType === 'other' && value.length < 5) {
      setMessageError('Message must be at least 5 characters.');
    }else{
      setMessageError(null)
    }       
  }
  }
  const options = {
    page: parseInt(props.versionReports.page || 0),
    sizePerPage: parseInt(props.versionReports.limit || 0),
    totalSize: parseInt(props.versionReports.totalCount || 0),
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.versionReports.totalCount) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.versionReports.totalCount) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  let columns = [
    {
      dataField: "fullNamel",
      text: "Last, First Name",
      sort: true,
      formatter: fullname,
    },
    {
      dataField: "nickname",
      text: "Nickname",
      sort: true,
      formatter: (cell, row) => {
        return row.nickname ? row.nickname : '-';
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell, row) => {
        return row.email ? row.email : '-';
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
      formatter: (cell, row) => {
        return row.phone ? row.phone : '-';
      },
    },
    {
      dataField: "mainCareProviders",
      text: "Main CareProvider",
      sort: true,
      formatter: (cell, row) => {
        return row.mainCareProviders ? row.mainCareProviders : '-';
      },
    },
    {
      dataField: "organization",
      text: "Organization",
      sort: true,
      formatter: (cell, row) => {
        return row.organization ? row.organization : '-';
      },
    }, {
      dataField: "applicationVersion",
      text: "App Version",
      sort: true,
      formatter: (cell, row) => {
        return row ? row.applicationVersion ?? '-' : '-';
      },
    },
    //  {
    //   dataField: "apiVersion",
    //   text: "API Version",
    //   sort: true,
    //   formatter: (cell, row) => {
    //       return row.versionReport  ? row.versionReport.apiVersion ?? '-' : '-';
    //     },
    // },
    {
      dataField: "systemName",
      text: "Platform",
      // sort: true,
      formatter: (cell, row) => {
        return row ? row.deviceInformation.platform ? (row.deviceInformation.platform.toLowerCase() == 'android' ? 'Android' : 'iOS') : row.deviceInformation.systemName ?? '-' : '-';
      },
    },
    {
      dataField: "applicationName",
      text: "App Status",
      sort: true,
      formatter: (cell, row) => {
        return row && row.applicationName ? row.applicationName.toLowerCase().includes("new") ? 'New' : 'Old' : '-';
      },
    },
    // {
    //   dataField: "createdAt",
    //   text: "First Login",
    //   // sort: true,
    //   formatter: (cell, row) => {
    //       return row && row.createdAt ?  moment(row.createdAt).format("MMMM DD, YYYY") : '-';
    //     },
    // },
    {
      dataField: "updatedAt",
      text: "Last Login",
      sort: true,
      formatter: (cell, row) => {
        return row && row.updatedAt ? moment(row.updatedAt).format("MMMM DD, YYYY") : '-';
      },
    },
    // {
    //   dataField: "Log out",
    //   text: "Log Out",
    //   // sort: true,
    //   formatter: (cell, row) => {
    //       return row && row.updatedAt ?  moment(row.updatedAt).format("MMMM DD, YYYY") : '-';
    //     },
    // },
    {
      dataField: "versionReport",
      text: "App Updated",
      formatter: (cell, row) => {
        // ios application new app
        if (row && row.deviceInformation && (row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase() == 'ios') || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'ios')) && ((row.applicationVersion) != (platformVersion.iosNewAppVersion))) {
          return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        }
        // android application new app
        else if (row && row.deviceInformation && (row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase()) == 'android' || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'android')) && ((row.applicationVersion) != (platformVersion.androidNewAppVersion))) {
          return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        }
        // ios application old app
        // if (row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase() == 'ios') || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'ios')) && ((row.applicationVersion) != (platformVersion.iosOldAppVersion))) { 
        //   return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        // }
        // // android application old app
        // else if (row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase()) == 'android' || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'android')) && ((row.applicationVersion) != (platformVersion.androidOldVersion))) {
        //   return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        // }
        if (row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase() == 'ios') || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'ios'))) { 
          return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        }
        // android application old app
        else if (row && row.deviceInformation && (row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && ((row.deviceInformation.systemName && row.deviceInformation.systemName.toLowerCase()) == 'android' || (row.deviceInformation.platform && row.deviceInformation.platform.toLowerCase() == 'android'))) {
          return <button className="btn btn-info close-click-cls m-0" onClick={() => { setShowUpdateModel(true); setSelectedUser(row) }}>Update</button>
        }
        else if (row && row.deviceInformation && (row.deviceInformation.systemName || row.deviceInformation.platform) && ((row.applicationName && !(row.applicationName.toLowerCase().includes("new"))) && (((row.applicationVersion) == (platformVersion.iosOldAppVersion)) || ((row.applicationVersion) == (platformVersion.androidOldVersion))) || ((row.applicationName && row.applicationName.toLowerCase().includes("new")) && ((row.applicationVersion) == (platformVersion.iosNewAppVersion)) || ((row.applicationVersion) == (platformVersion.androidNewAppVersion))))) {
          return <Badge color="success" pill outline> UPDATED </Badge>
        } else {
          return '-';
        }
      },
    },
  ]
  if (props.patientProfile) {
    columns = columns.filter(item => item.dataField !== 'recepient');
  }
  return (
    <div className="col-md-12 mx-auto mt-2"> <BootstrapTable
      remote={{
        sort: false,
        pagination: true,
        filter: true,
        search: true
      }
      }
      wrapperClasses="table-responsive"
      keyField="_id"
      noDataIndication={() => <NoDataIndication />}
      data={props.versionReports?.data || []}
      pagination={options.page > 0 ? paginationFactory(options) : undefined}
      columns={columns}
      bordered
      onTableChange={props.pagination}
    />
      <Modal isOpen={showUpdateModel} size="md" className="modal-dialog-centered">
        <ModalHeader toggle={() => { setShowUpdateModel(false); setSelectedUser(null) }}>
          {`Send app version update notification and SMS`}
        </ModalHeader>
        <ModalBody className="bg-white">
          <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <h4>User Information</h4>

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #ccc' }}>
                  <th style={{ padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Field</th>
                  <th style={{ padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>Last, First Name</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                    {selectedUser && selectedUser.fullNamel ? selectedUser.fullNamel : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>Phone</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                    {selectedUser && selectedUser.phone ? selectedUser.phone : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>App Version</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                    {selectedUser && selectedUser.applicationVersion ? selectedUser.applicationVersion : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>Platform</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                    {selectedUser
                      ? selectedUser.deviceInformation.platform
                        ? selectedUser.deviceInformation.platform.toLowerCase() === 'android'
                          ? 'Android'
                          : 'iOS'
                        : selectedUser.deviceInformation.systemName ?? '-'
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>App Status</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                    {selectedUser && selectedUser.applicationName
                      ? selectedUser.applicationName.toLowerCase().includes('new')
                        ? 'New'
                        : 'Old'
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
            <FormGroup tag="fieldset">
              <h4>Send Notification and SMS</h4>

              <FormGroup check>
                <Input
                  name="sendNotificationType"
                  type="radio"
                  id="option1"
                  style={{ marginLeft: '0px' }}
                  onChange={handlesendNotificationTypeChange}
                  value="updateNotification"
                  checked={selectedUpdateNotificationType === 'updateNotification'}
                />
                {' '}
                <Label check for="option1" style={{ marginLeft: '20px' }}>
                  {`You want to send an app version update or a new app install notification and SMS to the ${selectedUser && selectedUser.fullNamel ? selectedUser.fullNamel : 'user'}`}
                </Label>
              </FormGroup>

              <FormGroup check>
                <Input
                  name="sendNotificationType"
                  type="radio"
                  id="option2"
                  style={{ marginLeft: '0px' }}
                  onChange={handlesendNotificationTypeChange}
                  value="other"
                  checked={selectedUpdateNotificationType === 'other'}
                />
                {' '}
                <Label check for="option2" style={{ marginLeft: '20px' }}>
                  Other
                </Label>
              </FormGroup>
              {/* Display form level error if no radio button is selected */}
              {formError && <Label
                style={{
                  color: "red",
                  textAlign: "left",
                  paddingTop: '3px'
                }}>{formError}</Label>}
            </FormGroup>

             {selectedUpdateNotificationType === 'other' && <FormGroup>
              <Label for="exampleText">Subject</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="subject"
                value={notificationSubject}
                onChange={handleMessageChange}
                required  
              />
            </FormGroup>}

            {selectedUpdateNotificationType === 'other' && <FormGroup>
              <Label for="exampleText">Message</Label>
              <Input
                type="textarea"
                className="input-date-filter remove-bottom-margin"
                name="message"
                value={notificationMessage}
                onChange={handleMessageChange}
                rows={7}
                required  
              />
              {messageError && <Label
                style={{
                  color: "red",
                  textAlign: "left",
                  paddingTop: '3px'
                }}>{messageError}</Label>}
            </FormGroup>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <button type="submit" class="btn btn-info save-click-cls btn-block">
                Send
              </button>
            </div>

          </Form>
        </ModalBody>
      </Modal>

    </div>
  )
}

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {

  } = careprovider;
  return {

  };
};

export default connect(mapStateToProps)(VersionReport);
