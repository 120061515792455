import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup
} from "reactstrap";
import Select from "react-select";
import ProfileDetail from "../../Settings/ProfileDetail";
import _ from "lodash";
import { useDispatch } from "react-redux";
import LottieLoader from '../../../components/LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import PatientList from "./PatientList";
function SharedDetails(props) {
  const {
    row,
    users,
    handleCareprovider,
    additionalCareproviderList,
    handleAllPatients,
    ongoingAssignedUserRequest,
    handleAllAssignedCareproviders,
    ongoingAssignedCareproviderRequest,
    userAssignedCareprovider,
    selectedOption,
    userInputChange,
    handleClearAllAssignedCareprovider
  } = props;
  const dontChange = true;
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  useEffect(() => {
    handleAllAssignedCareproviders(row._id);
  }, []);

  useEffect(() => {
    dispatch({
      type: "DO_REMOVE_PROFILE_DATA"
    });
  }, [dontChange]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "DO_REMOVE_PROFILE_DATA"
      });
    };
  }, []);

  const updateAutoAddAllPatients = staffId => {
    dispatch({
      type: "DO_ADD_ALL_PATIENTS",
      payload: { staffId: staffId }
    });
    setTimeout(() => {
      dispatch({
        type: "DO_GET_STAFF"
      });
    }, 500);
    for(let i = 0; i < users.length; i++) {
      let patientId = users[i].receivedFrom
        ? users[i].receivedFrom._id
        : users[i]._id;
        document.getElementById(patientId).checked = true;
    }
  };

  const assignedCareProviders = [];
  if (userAssignedCareprovider) {
    for (const value of userAssignedCareprovider) {
      if(value){
        assignedCareProviders.push(
          <li key={value._id} className="li-margin">
            {value.fname} {value.lname}
          </li>
        );
      }
    }
  }
  return (
    <div>
      <Nav tabs className="justify-content-center">
        <NavItem>
          <NavLink
            className={activeTab == "1" ? "active" : ""}
            onClick={() => {setActiveTab("1"); handleAllAssignedCareproviders(row._id);}}
          >
            ASSIGN CAREPROVIDER
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == "2" ? "active" : ""}
            onClick={() => { setActiveTab("2"); handleAllPatients(row._id);}}
          >
            ASSIGN PATIENTS
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            {row.lname} {row.fname} PROFILE
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
          {" "}
          <Row>
            <Col>
              <Card body className="shared-account-card">
                <Row>
                {ongoingAssignedCareproviderRequest && (
                  <div className="text-center mx-auto">
                   <LottieLoader
                     loadLoop={true}
                     autoplayLoop={true}
                     source={lottieLoaderFile}
                     Lheight={150}
                     Lwidth={150}
                     subTitle={"Please Wait ..."}
                   />
                 </div>
                )}
                {!ongoingAssignedCareproviderRequest && (
                <div className="col-md-6">
                  {row.mainCareProvider && (
                    <p className="text-left">
                      <i className="mdi mdi-chevron-double-right icon-padding"></i>Main Care Provider:
                      <li key={row.mainCareProvider._id} className="li-margin">
                        {row.mainCareProvider.lname +
                          " " +
                          row.mainCareProvider.fname}{" "}
                      </li>
                    </p>
                  )}
                  {assignedCareProviders.length > 0 && (
                    <p className="text-left"><i className="mdi mdi-chevron-double-right icon-padding"></i>Additional Care Providers: {assignedCareProviders}</p>
                  )}
                </div>
                )}
                  <div className="col-md-6">
                    <div>
                      <CardTitle>
                        Pick CareProvider to Assign
                      </CardTitle>
                      <Select
                        isMulti={true}
                        onChange={(selectedOptions, action) => {
                          if (action.action === 'clear') {
                            handleClearAllAssignedCareprovider(row._id);
                          } else {
                            handleCareprovider(selectedOptions, row.user._id, row._id);
                          }
                        }}
                        getOptionLabel={option =>
                          option["fname"] + " " + option["lname"]
                        }
                        getOptionValue={option => option["_id"]}
                        options={additionalCareproviderList}
                        value={selectedOption}
                        isClearable={true}
                        isSearchable={true}
                      />
                    </div>
                </div>
                </Row>
              </Card>
            </Col>            
          </Row>
        </TabPane>
        <TabPane tabId="2">
          {" "} 
          <Row>
            <Col>
              <Card body className="shared-account-card">
              {ongoingAssignedUserRequest && (
                <div className="text-center mx-auto">
                  <LottieLoader
                    loadLoop={true}
                    autoplayLoop={true}
                    source={lottieLoaderFile}
                    Lheight={150}
                    Lwidth={150}
                    subTitle={"Please Wait ..."}
                  />
                </div>
              )}
              {(!ongoingAssignedUserRequest && _.size(users) == 0) && (
                <div className="text-center mx-auto">
                  <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieEmptyFile}
                    Lheight={300}
                    Lwidth={300}
                    title={"Sorry! No Patients found :("}
                    subTitle={"We can't find any patients for assign."}
                  />  
                </div>
              )}
              {(!ongoingAssignedUserRequest && _.size(users) > 0) && (
                <>
                <CardTitle className="text-center">
                  Pick Patient to Assign
                </CardTitle>
                <Form>
                  <FormGroup>
                    {/* <Row>
                    <CustomInput
                      type="checkbox"
                      id="addAll"
                      onChange={e => updateAutoAddAllPatients(row.user._id)}
                      label="Add All"
                    />
                    </Row> */}

                    {/* <Row>
                      {_.size(users) > 0 &&
                        users.map((object, i) => {
                          const patientId = object.receivedFrom
                            ? object.receivedFrom._id
                            : object._id;
                          const fname = object.receivedFrom
                            ? object.receivedFrom.fname
                            : object.fname;
                          const lname = object.receivedFrom
                            ? object.receivedFrom.lname
                            : object.lname;
                          const isUserFound = _.includes(
                            row.assignedUsers,
                            patientId
                          );
                          return (
                            <Col xs="2">
                              <CustomInput
                                type="checkbox"
                                defaultChecked={isUserFound}
                                id={patientId}
                                name={patientId}
                                onChange={e => userInputChange(e, row._id)}
                                label={fname + " " + lname}
                              />
                            </Col>
                          );
                        })}
                    </Row> */}
                    <PatientList
                      data={users}
                      sizePerPage={10}
                      page={1}
                      totalSize={users.length}
                      loading={false}
                      readingSubTypes={[]}
                      profileOwner={[]}
                      userInputChange={userInputChange}
                      staffId={row._id}
                    />
                  </FormGroup>
                </Form>
                </>
              )}
              </Card>
            </Col>            
          </Row>
        </TabPane>
        <TabPane tabId="3">
          {" "}
          <Row className="pt-2" style={{margin:15}}>
            {activeTab === "3" && (
              <ProfileDetail
                hideTitle={true}
                enableUserEdit={false}
                currentUserId={row.user._id}
              />
            )}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default SharedDetails;