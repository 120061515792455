import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import LineGraph from "./LineGraph";
import TableReading from "./TableReading";
import ReactToPrint from "react-to-print";
import TableReadingPrint from "./TableReadingPrint";
import GraphTableReading from "./GraphTableReading";
import LottieLoader from '../LottieLoader';
import lottieLoaderFile from '../../files/animation/loading.json';
import DisplayCardThreshold from "./DisplayCardThreshold";
import Swal from 'sweetalert2';
export const BodyTraceBPData = ({
  bodytraceBP,
  startDate,
  endDate,
  onChangeDateRange,
  dateRange,
  exportReadingCsv,
  emailReadingReport,
  unitMeasurement,
  profile,
  expectationRule,
  expectationWeightRule,
  notesPatientDetails,
  latestReadingUser,
  ccmTotalDuration,
  rpmTotalDuration,
  ongoingbothReadingRequest,
  ongoingPdfExcelRequest,
  deleteReading
}) => {
  const [showModal, setShowModal] = React.useState(false);
  // Change date format
  const dateFrom = moment(startDate).startOf('day').clone().format("MMMM DD, YYYY");
  const dateTo = moment(endDate).endOf('day').clone().format("MMMM DD, YYYY");
  const dateFrom1 = moment(startDate).startOf('day').clone().format();
  const dateTo1 = moment(endDate).endOf('day').clone().format();
  // Filter readings type
  const filterReadingType = bodytraceBP.filter(function (value) {
    return moment(value.deviceReadingDate).isBetween(dateFrom1, dateTo1);
  });
  const printGraph = React.useRef();
  const graphDiv = React.useRef();
  const tableExportRef = React.useRef();
  const printTable = React.useRef();
  const printGraphTableRef = React.useRef();
  // Order by readings date
  const orderByTheFilter = _.orderBy(
    filterReadingType,
    [
      function (object) {
        return new Date(object.deviceReadingDate);
      }
    ],
    ["asc"]
  );
  // Read array and map out values and device reading date
  const readingValueWithDate = _.map(bodytraceBP, function (object) {
    return {
      _id: object._id,
      readingValue: object.values,
      value: [
        object.values.systolic,
        object.values.diastolic,
        object.values.pulse
      ],
      comments: object.comments,
      deviceReadingDate: object.deviceReadingDate
    };
  });
  // Order by reading date filter
  const readingDate = _.map(orderByTheFilter, function (value, index) {
    return moment(value.deviceReadingDate).format("MMMM DD, YYYY hh:mm a");
  });
  let readingDataSet = [];
  let readingThreshold = [];
  const subType = ["systolic", "diastolic", "pulse"];
  const subTypeColor = ["#34626C", "#ff4816", "#9BB67C"];
  _.map(subType, function (v, i) {
    const matchingExpectationsBreached = _.filter(expectationRule, function (o) {
      return (
        o && o.readingSubType.toLowerCase() == _.replace(v,"pulse","Heart Rate").toLowerCase() &&
        !o.name.toLowerCase().includes("Event".toLowerCase())
      );
    });

    // Checked all of the readings to see which had a low threshold.
    var lowExpectation = _.flatMap(_(matchingExpectationsBreached)
      .filter((o) => o && o.condition == "<=" || o.condition == "between")
      .flatMap((_value, i) => _.pick(_value, "valueProperty"))
      .value(), "valueProperty");

    var highExpectation = _.flatMap(_(matchingExpectationsBreached)
      .filter((o) => o && o.condition == ">=" || o.condition == "between")
      .flatMap((_value, i) => _.pick(_value, "valueProperty"))
      .value(), "valueProperty");

    var lowValueThreshold = _.maxBy(_.map(lowExpectation, i => i.minValue || i.value), function(o) { return o && parseInt(o) });
    var highValueThreshold = _.minBy(_.map(highExpectation, i => i.maxValue || i.value), function(o) { return o && parseInt(o) }); 

    const lowValue = lowValueThreshold ? lowValueThreshold : 0;
    const highValue = highValueThreshold ? highValueThreshold : 0;
    const label = _.replace(v,"pulse","Heart Rate");
    readingThreshold.push({
      lowThreshold: lowValueThreshold,
      highThreshold: highValueThreshold,
      readingSubTypeName: label.charAt(0).toUpperCase() + label.slice(1)
    });
    if (lowValue > 0) {
      const lowExpectations = _.map(readingValueWithDate, function (rawvalue, index) {
        return { y: Math.round(lowValue) };
      });
      readingDataSet.push({
        label: `Low (${_.replace(v,"pulse","Heart Rate")})`,
        fill: false,
        radius: 2,
        pointRadius: 1,
        pointHoverRadius: 1,
        stepped: false,
        borderWidth: 2,
        borderDash: [2],
        pointStyle: 'rectRot',
        borderColor: subTypeColor[i],
        pointBackgroundColor: "transparent",
        data: lowExpectations
      });
    }
    if (highValue > 0) {
      const highExpectations = _.map(readingValueWithDate, function (_rawvalue) {
        return { y: Math.round(highValue) };
      });
      readingDataSet.push({
        label: `High (${_.replace(v,"pulse","Heart Rate")})`,
        fill: false,
        radius: 2,
        pointRadius: 1,
        pointHoverRadius: 1,
        stepped: false,
        pointStyle: 'rectRot',
        pointRadius: 2,
        borderWidth: 2,
        borderDash: [5, 2],
        borderColor: subTypeColor[i],
        pointBackgroundColor: "transparent",
        data: highExpectations
      });
    }
    const newReading = _.map(readingValueWithDate, function (rawvalue, index) {
      return { y: Math.round(rawvalue.readingValue[v]) };
    });
    // Dataset for chart
    readingDataSet.push({
      label: _.replace(v,"pulse","Heart Rate"),
      fill: false,
      pointRadius: 4,
      pointHoverRadius: 4,
      backgroundColor: "#ff0000",
      pointRadius: 5,
      pointHoverRadius: 5,
      borderColor: subTypeColor[i],
      pointBackgroundColor: subTypeColor[i],
      data: newReading
    });
  });

  const bDateFrom = moment(dateFrom).clone().format("MMMM DD, YYYY");
  const bDateTo = moment(dateTo)
    .clone()
    .subtract(1, "d")
    .format("MMMM DD, YYYY");

  const bloodPressureSubTypes = [
    {
      name: "Systolic",
      unit: "mmHg",
      color: "#007fad",
      _id: "5be56c1a3863cd0647d79891"
    },
    {
      name: "Diastolic",
      unit: "mmHg",
      color: "#e17806",
      _id: "5be56c293863cd0647d79892"
    },
    {
      name: "Heart Rate",
      unit: "bpm",
      color: "#b22746",
      _id: "5be56c493863cd0647d79894"
    },
    {
      name: "Mean Arterial",
      unit: "",
      color: "#94B49F",
      _id: "5be56c383863cd0647d79893"
    }
  ];

  const readingType = {
    _id: "633c2614ebfa2e7e9022fab2",
    abbrev: "BT_BP",
    description: "Bodytrace Blood Pressure",
  };
  return (
    <Col sm="6" className="mt-4">
      <Card className="card-design" style={{ backgroundColor: "#fffff" }}>
        <CardBody className="line-graph-card-padding">
          <div class="card-title row">
            <div class="col-9">
              <div>
                <div className="text-left reading-card-for-all-reading">
                  <img
                    src={require(`../../files/Reading/BT_BP.svg`)}
                    alt="profile-image"
                    className="profile rounded-circle img-fluid mr-2 reading-icon"
                  /> <div>
                    <h4 class="reading-card-title">BodyTrace Blood Pressure</h4>
                    <h6 class="reading-card-subtitle">{`${dateFrom} - ${dateTo}`}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 show-more-button-center">
              <a
                target="_blank"
                onClick={() => setShowModal(!showModal)}
                className="text-white ml-auto btn btn-sm display-chart-show-more"
              >
                Show More Details
              </a>
            </div>
          </div>
          <LineGraph
            readingDate={readingDate}
            readingDataSet={readingDataSet}
            hidePrint="d-none"
            dontRender="true"
            title={`(${dateFrom} - ${dateTo})`}
          />
          <div />
        </CardBody>
        <DisplayCardThreshold
          readingThreshold={readingThreshold}
        />
      </Card>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        className="modal-lg modal-dialog-centered"
        style={{ maxWidth: "77%" }}
      >
        <ModalHeader className="close_button" toggle={() => setShowModal(!showModal)}>
          <Label className="align-items-center justify-content-center text-center">BodyTrace Blood Pressure</Label>
          <button
            style={{ float: 'right' }}
            onClick={() => {
              setShowModal(!showModal)
            }}
            className="btn btn-info ml-2 close-click-cls"
          >
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>
            Close
          </button>

           <ReactToPrint
            trigger={() => (
            <div className="pull-right">
              <button
                  className="btn btn-info ml-2 print-click-cls"
                  style={{ float: 'right' }}>
                  <i class="mdi mdi-cloud-print" style={{
                    fontSize: 20
                  }}></i>
                  Print Graph
                </button>
              </div>
            )}                                      
            content={() => graphDiv.current}
          />
          <ReactToPrint
            trigger={() => (
              <div className="pull-right">
                <button
                  className="btn btn-info ml-2 print-data-table-click-cls"
                  style={{ float: 'right' }}>
                  <i class="mdi mdi-cloud-print" style={{
                    fontSize: 20
                  }}></i>
                  Print Logbook
                </button>
              </div>
            )}
            content={() => printTable.current}
          />
          <ReactToPrint
              trigger={() => (
              <div className="pull-right">
                <button
                    className="btn btn-info ml-2 print-whole-data-table-click-cls"
                    style={{ float: 'right' }}>
                    <i class="mdi mdi-cloud-print" style={{
                      fontSize: 20
                    }}
                    ></i>
                    Print Graph & Logs
                  </button>
                </div>
              )}                                      
              content={() => printGraphTableRef.current}
            />
          {/* <button
              className="btn btn-info ml-3 print-graph-table-click-cls"
              style={{ float: 'right' }}
              onClick={() => {
                                                        
              }}
            >
              <i class="mdi mdi-cloud-print" style={{
                fontSize: 20
              }}></i>
              Print Graph & Reading
            </button> */}

          <button
            className="btn btn-info ml-2 download-click-cls"
            style={{ float: 'right' }}
            // onClick={() => {
            //   // tableExportRef.current.click();
            //   getCsvFile(readingType._id)
            // }}
            onClick={() => exportReadingCsv(readingType._id,true,false)}
          >
            <i class="mdi mdi-download" style={{
              fontSize: 20
            }}></i>
            Export CSV
          </button>
          <button
            className="btn btn-info ml-2 reading-report-email"
            style={{ float: 'right' }}
            onClick={async () => {
              const { value: email } = await Swal.fire({
                title: "Reading Report!",
                input: "email",
                inputLabel: "Your email address",
                inputPlaceholder: "Enter your email address",
                confirmButtonText: 'Sent',
                showCancelButton: false,
              });
              if (email) {
                emailReadingReport(readingType._id,true, false, email);           
              }
            }}>
            <i class="mdi mdi-email" style={{
              fontSize: 20
            }}></i>
            Email
          </button>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "white" }}>
          <div className="text-center">
            <select
              name="dateRange"
              className="date-range-option"
              value={dateRange}
              onChange={onChangeDateRange}
            >
              <option value="7">7 Days</option>
              <option value="1">1 Month</option>
              <option value="6">6 Months</option>
              <option value="custom">Custom Date</option>
            </select>
          </div>
          <div
            className={`text-center col-md-6 mx-auto ${dateRange !== "custom" ? "d-none" : ""
              }`}
          >
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail">Start Date</Label>
                  <Input
                    className="input-date-filter"
                    type="date"
                    name="startDate"
                    onChange={onChangeDateRange}
                    value={startDate}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword">End Date</Label>
                  <Input
                    className="input-date-filter"
                    type="date"
                    name="endDate"
                    onChange={onChangeDateRange}
                    value={endDate}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          {(ongoingbothReadingRequest || ongoingPdfExcelRequest) && (
            <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
          )}
          {(!ongoingbothReadingRequest && !ongoingPdfExcelRequest) && (
            <>
          <LineGraph
            ref={printGraph}
            readingDate={readingDate}
            readingDataSet={readingDataSet}
            title={`(${dateFrom} - ${dateTo})`}
          />
          <hr></hr>
          <DisplayCardThreshold
            isHideBorder={true}
            leftAline={true}
            readingThreshold={readingThreshold}
          />
          <TableReading
            ref={tableExportRef}
            reading={readingValueWithDate}
            subType={bloodPressureSubTypes}
            readingValue={_.map(readingValueWithDate, "value")}
            unitMeasurement={unitMeasurement}
            date={`(${bDateFrom} - ${bDateTo})`}
            fname={profile.fname}
            lname={profile.lname}
            deleteReading={deleteReading}
          />
          </>
          )}
          <div className="hideChart">
            <GraphTableReading
              ref={printGraphTableRef}
              readingThreshold={readingThreshold}
              readingDate={readingDate}
              readingDataSet={readingDataSet}
              readingType={readingType}
              reading={readingValueWithDate}
              subType={bloodPressureSubTypes}
              readingValue={_.map(readingValueWithDate, "value")}
              unitMeasurement={unitMeasurement}
              startDate={bDateFrom}
              endDate={bDateTo}
              date={`(${bDateFrom} - ${bDateTo})`}
              fname={profile.fname}
              lname={profile.lname}
              notesPatientDetails={notesPatientDetails}
              latestReadingUser={latestReadingUser}
              ccmTotalDuration = {ccmTotalDuration}
              rpmTotalDuration = {rpmTotalDuration}
              callingThroughValue = 'graphlogbook'
            />
          </div>
          <div className="hideChart">
            <GraphTableReading
              ref={graphDiv}
              readingDate={readingDate}
              readingThreshold={readingThreshold}
              readingDataSet={readingDataSet}
              readingType={readingType}
              reading={readingValueWithDate}
              subType={bloodPressureSubTypes}
              readingValue={_.map(readingValueWithDate, "value")}
              unitMeasurement={unitMeasurement}
              startDate={bDateFrom}
              endDate={bDateTo}
              date={`(${bDateFrom} - ${bDateTo})`}
              fname={profile.fname}
              lname={profile.lname}
              notesPatientDetails={notesPatientDetails}
              latestReadingUser={latestReadingUser}
              ccmTotalDuration = {ccmTotalDuration}
              rpmTotalDuration = {rpmTotalDuration}
              callingThroughValue = 'graph'
            />
          </div>
          <div className="hideChart">
            <GraphTableReading
              ref={printTable}
              readingThreshold={readingThreshold}
              readingDate={readingDate}
              readingDataSet={readingDataSet}
              readingType={readingType}
              reading={readingValueWithDate}
              subType={bloodPressureSubTypes}
              readingValue={_.map(readingValueWithDate, "value")}
              unitMeasurement={unitMeasurement}
              startDate={bDateFrom}
              endDate={bDateTo}
              date={`(${bDateFrom} - ${bDateTo})`}
              fname={profile.fname}
              lname={profile.lname}
              notesPatientDetails={notesPatientDetails}
              latestReadingUser={latestReadingUser}
              ccmTotalDuration = {ccmTotalDuration}
              rpmTotalDuration = {rpmTotalDuration}
              callingThroughValue = 'logbook'
            />
          </div>
        </ModalBody>
      </Modal>
    </Col>
  );
};
