import classnames from "classnames";
import _ from "lodash";
import React, { Component } from "react";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardHeader,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import { toast } from "react-toastify";
import RequiredSelect from "./RequiredSelect";
class ReminderDetails extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "DO_GET_USERS_LIST"
    });

    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      countTime: 0,
      timezone: this.props.timezone,
      timezoneList: this.props.timezoneList,
      body: ""
    };
    this.addTime = this.addTime.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
    this.minusTime = this.minusTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleCondition = this.handleCondition.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  handleTimezone = (selectedOption) => {
    this.setState({
      body: {
        ...this.state.body,
        timezone: selectedOption.value
      },
      timezone: selectedOption
    });

    
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  handleChange = (selectedOption) => {
    this.setState({ readingSubType: selectedOption });
  
    this.setState({
      body: {
        ...this.state.body,
        readingType: selectedOption.reading,
        readingSubType: selectedOption.name,
        readingSubTypeId: selectedOption._id
      }
    });
  };
  submit(e) {
    e.preventDefault();

    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state.body?.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body?.messageCareprovider?.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } else {
      this.props.dispatch({
        type: "DO_SAVE_TIME_ADHERENCE_RULE",
        payload: this.state.body
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_MY_RULES"
        });
        this.props.dispatch({
          type: "DO_GET_RULES_SET_BY_OTHERS"
        });
      }, 500);
    }
  }
  handleCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        condition: selectedOption.value,
        valueProperty: []
      },
      condition: selectedOption,
      showInputCondition: true
    });

    
    if (selectedOption.value == "between") {
      this.setState({
        showRangeInput: true
      });
    } else {
      this.setState({
        showRangeInput: false
      });
    }
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id")
      }
    });
  };
  handleDateChange(event, i) {
    const time = this.state.body.time;
    time[i] = event.format("hh:mm A");
    this.setState({
      body: {
        ...this.state.body,
        time: time
      }
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    let notifySelf = this.state.body.notifySelf;
    let notifyReadingOwner = this.state.body.notifyReadingOwner;
    let notifyStaff = this.state.body.notifyStaff;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
        // add the numerical value of the checkbox to options array
        if (name === "notifySelf") {
          if(!notifySelf){
            notifySelf = [];
          }
          notifySelf.push(event.target.value);
        } else if (name === "notifyStaff") {
          if(!notifyStaff){
            notifyStaff = [];
          }
          notifyStaff.push(event.target.value);
        } else {
          if(!notifyReadingOwner){
            notifyReadingOwner = [];
          }
          notifyReadingOwner.push(event.target.value);
        }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        }
        if (name === "notifyStaff") {
          index = notifyStaff.indexOf(event.target.value);
          notifyStaff.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner,
          notifyStaff: notifyStaff
        }
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
          valueProperty: { ...this.state.body.valueProperty, [name]: value }
        }
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: _.map(selectedOption, "_id")
      }
    });

    
  };
  addTime() {
    this.setState((prevState, props) => ({
      countTime: prevState.countTime + 1
    }));
  }
  minusTime() {
    const lengthTime = _.size(this.state.body.time) + this.state.countTime;
    const time = this.state.body.time.filter(function (i, index) {
      return index != lengthTime - 1;
    });
    this.setState((prevState, props) => ({
      countTime: 0,
      body: {
        ...this.state.body,
        time: time
      }
    }));
  }

  componentDidMount() {
    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId]
        }
      });
    }
    this.setState({ body: this.props.ruleDetails });
  }

  render() {
    let timeElements = [];
    const lengthTime = _.size(this.state.body.time) + this.state.countTime;
    for (var i = 0; i < lengthTime; i++) {
      const indexOfElement = i;
      timeElements.push(
        <FormGroup key={indexOfElement}>
          <Label for="exampleSelect">Pick a Specific Time for Reminders</Label>
          <Row form>
            <Col md={11}>
              <Datetime
                className="custom-input"
                dateFormat={false}
                defaultValue={this.state.body.time[indexOfElement]}
                inputProps={{
                  placeholder: "Input Desired Time",
                  required: true
                }}
                onChange={(event) =>
                  this.handleDateChange(event, indexOfElement)
                }
              />
            </Col>
            <Col md={1}>
              {i != 0 && (
                <button
                  type="button"
                  className="btn btn-gradient-dark btn-rounded btn-icon"
                  onClick={this.minusTime}
                >
                  <i className="mdi mdi-delete" />
                </button>
              )}
            </Col>
          </Row>
        </FormGroup>
      );
    }
    const getUsers = this.props.ruleDetails.sharedUsers;
    const defaultUsers = _.filter(
      this.props.assignedUsersList,
      (item) => getUsers.indexOf(item._id) > -1
    );
    const conditionGroup = this.props.ruleDetails.conditionGroup;
    const defaultConditionGroup = _.filter(
      this.props.conditions,
      (item) => conditionGroup.indexOf(item._id) > -1
    );
    return (
      <div>
        <Form onSubmit={this.submit}>
          <Card>
            <CardHeader
              tag="h4"
              className="justify-content-center"
              style={{ background: "white" }}
            >
              <span className="text-center">
                {this.props.ruleDetails.name}
              </span>

              {this.props.isCareProvider && (
                <>
                  {" "}
                  <button
                    style={{ float: 'right' }}
                    onClick={this.props.isDelete}
                    type="button"
                    className="btn btn-info ml-2 close-click-cls" >
                    <i class="mdi mdi-close" style={{
                      fontSize: 20
                    }}></i>
                    Delete
                  </button>
                  <button
                    name="submit"
                    style={{ float: 'right' }}
                    className="btn btn-info ml-2 print-click-cls" >
                    <i class="mdi mdi-content-save" style={{
                      fontSize: 20,
                      marginRight: 5
                    }}></i>
                    Update
                  </button>
                </>
              )}
            </CardHeader>
          </Card>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body project-tab pt-2" style={{paddingBottom:0}}>
                  <Nav tabs className="justify-content-center">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        GENERAL
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        NOTIFICATIONS
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3"
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        PATIENTS
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          <FormGroup>
                            <Label for="exampleSelect">Select a Timezone</Label>
                            <RequiredSelect
                              SelectComponent={Select}
                              value={this.state.timezone}
                              onChange={this.handleTimezone}
                              options={this.props.timezoneList}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleEmail">Expectation Name</Label>
                            <Input
                              type="text"
                              name="name"
                              id="exampleEmail"
                              defaultValue={this.state.body.name}
                              required={true}
                              onChange={this.handleInputChange}
                              placeholder="Expectation Name"
                            />
                          </FormGroup>
                       
                      {timeElements}
                      <button
                        type="button"
                        className="btn btn-dark btn-sm btn-block"
                        onClick={this.addTime}
                      >
                        ADD MORE TIME FOR REMINDERS
                      </button>
                      </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          <FormGroup>
                            <Label for="exampleText">
                              Notification Message (Patient)
                            </Label>
                            <Input
                              className="input-date-filter"
                              type="textarea"
                              name="message"
                              value={this.state.body.message}
                              onChange={this.handleInputChange}
                              rows={10}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="exampleText">
                              Notification Message (Careprovider)
                            </Label>
                            <Input
                              className="input-date-filter"
                              type="textarea"
                              name="messageCareprovider"
                              value={this.state.body.messageCareprovider}
                              onChange={this.handleInputChange}
                              rows={10}
                            />
                          </FormGroup>
                          {this.props.isCareProvider && (
                            <FormGroup>
                              <Label for="exampleCheckbox">
                                Include yourself for reminder?
                              </Label>
                              <div>
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "inbox"
                                  )}
                                  onChange={this.handleInputChange}
                                  value="inbox"
                                  label="INBOX"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline2"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "sms"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="SMS"
                                  value="sms"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline3"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "voice"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="VOICE"
                                  value="voice"
                                  inline
                                />
                                <CustomInput
                                  type="checkbox"
                                  id="exampleCustomInline4"
                                  name="notifySelf"
                                  checked={_.includes(
                                    this.state.body.notifySelf,
                                    "email"
                                  )}
                                  onChange={this.handleInputChange}
                                  label="EMAIL"
                                  value="email"
                                  inline
                                />
                              </div>
                            </FormGroup>
                          )}

                          <FormGroup>
                            {this.props.isCareProvider && (
                              <Label for="exampleCheckbox">
                                Remind the Patient you Choose
                              </Label>
                            )}

                            <div>
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline6"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                value="inbox"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "inbox"
                                )}
                                label="INBOX"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline7"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "sms"
                                )}
                                label="SMS"
                                value="sms"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline8"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                label="VOICE"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "voice"
                                )}
                                value="voice"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline9"
                                name="notifyReadingOwner"
                                onChange={this.handleInputChange}
                                label="EMAIL"
                                value="email"
                                checked={_.includes(
                                  this.state.body.notifyReadingOwner,
                                  "email"
                                )}
                                inline
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            {this.props.isCareProvider && (
                              <Label for="exampleCheckbox">Notify Staff</Label>
                            )}

                            <div>
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline10"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                value="inbox"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "inbox"
                                )}
                                label="INBOX"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline11"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "sms"
                                )}
                                label="SMS"
                                value="sms"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline12"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                label="VOICE"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "voice"
                                )}
                                value="voice"
                                inline
                              />
                              <CustomInput
                                type="checkbox"
                                id="exampleCustomInline13"
                                name="notifyStaff"
                                onChange={this.handleInputChange}
                                label="EMAIL"
                                value="email"
                                checked={_.includes(
                                  this.state.body.notifyStaff,
                                  "email"
                                )}
                                inline
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12" className="grid-margin mt-5">
                          {this.props.isCareProvider && (
                            <>
                              <FormGroup>
                                <Label for="exampleSelect">
                                  Select a Condition to Receive the Reminders
                                </Label>
                                <Select
                                  defaultValue={defaultConditionGroup}
                                  onChange={this.handleConditions}
                                  options={this.props.conditions}
                                  isMulti={true}
                                  getOptionLabel={(option) =>
                                    `${option["condition"]}  (${option["description"]})`
                                  }
                                  getOptionValue={(option) => option["_id"]}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="exampleSelect">
                                  Select a user to receive reminder
                                </Label>
                                <Select
                                  defaultValue={defaultUsers}
                                  onChange={this.handleUsers}
                                  options={this.props.assignedUsersList}
                                  isMulti={true}
                                  getOptionLabel={(option) =>
                                    option["fname"] + " " + option["lname"]
                                  }
                                  getOptionValue={(option) => option}
                                />
                              </FormGroup>
                            </>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
          <div className="row" />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    usersOfferedAccess,
    isGenericSuccess,
    isGenericError,
    myRules,
    conditions,
    genericErrorMessage,
    assignedUsersList
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  return {
    readingSubTypes,
    usersOfferedAccess,
    userId,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    myRules,
    conditions,
    isCareProvider,
    assignedUsersList
  };
};

export default connect(mapStateToProps)(ReminderDetails);
